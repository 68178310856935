import styled from 'styled-components/native';
import { Button } from '../../../components/Button';
import { theme } from '../../../global/theme';

export const Container = styled.View`
  align-items: center;
  align-self: center;
  width: 90%;
`;

export const Title = styled.Text`
  font-size: 18px;
  text-align: center;
  width: 75%;
  font-family: ${theme.fonts.bold};
`;

export const Description = styled.Text`
  text-align: center;
  font-size: 14px;
  width: 75%;
  font-family: ${theme.fonts.regular};
  margin-top: 16px;
`;

export const ContinueButton = styled(Button)`
  margin-top: 24px;
`;

export const CancelButtonWrapper = styled.TouchableOpacity`
  width: 100%;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 5px;
`;

export const CancelButton = styled.Text`
  font-size: 14px;
  font-family: ${theme.fonts.bold};
  text-decoration: underline;
  color: ${theme.colors.secondary};
`;
