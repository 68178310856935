import React, { useState } from 'react'
import { Modal as ReactNativeModal } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Overlay,
  ModalArea,
  ModalAreaHeader,
  IconCloseModal,
  CloseIcon,
  ModalContent,
  ModalLabelInput,
  InputContentMail,
  InputLabel,
  InputLabelMandatory,
  InputTextArea,
  ButtonSend,
  ButtonSendText,
  ErrorMessage,
} from './styles';

import { theme } from '../../global/theme';
import { formatCPF, isValidCPF } from '../../utils/formatCpf';

interface SendReceiptProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  userCPF: string
  setUserCPF: (value: string) => void
}

declare module 'yup' {
  interface StringSchema {
    isValidCpf(errorMessage: string): StringSchema<string>;
  }
}

Yup.addMethod(Yup.string, 'isValidCpf', function (errorMessage: string) {
  return this.test('test-if-is-valid-cpf', errorMessage, function (cpf) {
    if (!cpf || cpf.trim() === '') {
      return true;
    }
    return isValidCPF(cpf);
  });
});

const CpfSchema = Yup.object({
  cpf: Yup.string().isValidCpf('CPF inválido'),
})

const ModalAddCpf: React.FC<SendReceiptProps> = ({ setOpen, userCPF, setUserCPF }) => {

  const [isFocused, setIsFocused] = useState(false);


  const handleSendCpf = async (values: { cpf: string }) => {
    setUserCPF(values.cpf || "")
    setOpen(false)
  }

  return (
    <ReactNativeModal transparent={true} visible animationType="slide">
      <Overlay>
        <ModalArea>
          <ModalAreaHeader>
            <IconCloseModal onPress={() => {
              setOpen(false)
            }}>
              <CloseIcon source={require('../../../assets/close.svg')} />
            </IconCloseModal>
          </ModalAreaHeader>
          <ModalContent>
            <>
              <ModalLabelInput>
                {userCPF ? "Alterar CPF" : "Adicionar CPF"}
              </ModalLabelInput>
              <Formik
                initialValues={{ cpf: userCPF }}
                validationSchema={CpfSchema}
                onSubmit={handleSendCpf}
              >
                {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
                  <>
                    <InputContentMail isFocused={isFocused} style={{ borderColor: errors.cpf && `${theme.colors.red}` }}>
                      <InputLabel>
                        CPF
                        <InputLabelMandatory>*</InputLabelMandatory>
                      </InputLabel>
                      <InputTextArea
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => {
                          handleBlur('cpf')
                          setIsFocused(false)
                        }}
                        onChangeText={(value: string) => {
                          const formattedValue = formatCPF(value);
                          handleChange('cpf')(formattedValue);
                        }}
                        value={values.cpf}
                      />
                      {errors.cpf &&
                        <ErrorMessage>
                          {errors.cpf}
                        </ErrorMessage>
                      }
                    </InputContentMail>
                    <ButtonSend onPress={() => handleSubmit()}>
                      <ButtonSendText>
                        {userCPF ? "Alterar CPF" : "Adicionar CPF"}
                      </ButtonSendText>
                    </ButtonSend>
                  </>
                )}
              </Formik>
            </>
          </ModalContent>
        </ModalArea>
      </Overlay>
    </ReactNativeModal>
  )
}

export default ModalAddCpf