import styled from 'styled-components/native';
import { theme } from '../../global/theme';


export const Container = styled.View`
  align-items: center;
`;

export const Content = styled.View`
  padding: 0 20px;
  align-items: center;
`;

export const Title = styled.Text`
  font-size: 18px;
  font-family: ${theme.fonts.bold};
  margin-top: 14px;
  margin-bottom: 6px;
  text-align: center;
`;

export const Description = styled.Text`
  font-size: 12px;
  font-family: ${theme.fonts.regular};
  margin-bottom: 30px;
  text-align: center;
`;

export const Actions = styled.View`
  flex-direction: row;
  justify-content: space-between;
  border-top-width: 1px;
  border-top-color: #949494;
`;

export const TextButton = styled.Text`
  color: #2081ff;  
  font-family: ${theme.fonts.bold};
`;
