export const theme =  {
    colors: {
      primary: '#FF7700', // orange
      secondary: '#2081FF', // blue
      tertiary: '#30374A', // darkBlue
      disabled: '#F7F7F7',
      disabledButtonText: '#A7A7A7',
  
      overlay: 'rgba(0,0,0,0.7)',
      gray: '#B0B0B0',
      lightGray: '#F6F6F6',
      border: '#DEDEDE',
      // missing #BABABA
      // missing #E2E2E2
      missingGray: '#838383',
      anotherGray: '#626262',
  
  
      red: '#FF4E4E',
      green: '#20AC54',
  
      darkGreen: '#039D00',
      black: '#262626',
      white: '#FFFFFF',
    },
  
    fonts: {
      regular: 'Montserrat_400Regular',
      medium: 'Montserrat_500Medium',
      semiBold: 'Montserrat_600SemiBold',
      bold: 'Montserrat_700Bold',
    }
  };