import { Feather } from "@expo/vector-icons";
import { Animated } from "react-native";
import styled from "styled-components/native";
import { theme } from "../../global/theme";

interface TypeProps {
	type: "default" | "compact" | "full";
}

export const Container = styled(Animated.View) <TypeProps>`
 flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
	height: 45px;
  overflow: hidden;
  border: 1px solid #EDEDED;
  background-color: ${theme.colors.white};
`;

export const Minus = styled(Feather).attrs({
	name: "minus",
	size: 17,
})`
  padding: 10px;
`;

export const QuantityText = styled.Text`
	text-align: center;
	font-family: ${theme.fonts.bold};
	font-size: 14px;
  width: 30px;
  color: #30374A;
`;

interface PlusProps {
	open: boolean;
}

export const Plus = styled(Feather).attrs({
	name: "plus",
	size: 17,
}) <PlusProps>`
	${(props) =>
		props.open
			? `
    padding: 10px;
    `
			: `
    padding: 9px;
    `}
`;
