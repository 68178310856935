import React from 'react';
import { FontAwesome } from '@expo/vector-icons'; 
import { FAB } from 'react-native-paper';
import { useNavigation } from "@react-navigation/native";
import { View } from 'react-native';

const IconSearch = () => {
  return (
    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <FontAwesome name="search" size={20} color="white" />
    </View>
  );
}

const SearchButton: React.FC = () => {

  const navigation = useNavigation();

  const handleNavigateTo = () => {
    navigation.navigate("PesquisarProdutos");
  };

  return (
      <FAB
        style={{ backgroundColor: '#FF7700' }}
        icon={IconSearch}
        onPress={() => handleNavigateTo()}
      />
  );
}

export default SearchButton;