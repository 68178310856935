import axios, { AxiosRequestConfig } from "axios";
import { endpoints } from "../env";

import pkg from "../../package.json";

const api = axios.create({
  baseURL: endpoints.api,
});

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  config.headers = config.headers || {};
  const headers = config.headers;

  headers['x-app-version'] = pkg?.version || '',
  headers['x-platform'] = "web"
  return config;
});

export default api;