import React, { useState } from "react";
import CarouselItem from "./CarouselItem ";
import { Container, StyleDotted } from "./styles";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Advert } from "@inhouse-market/sdk";

interface ProductsClassProps {
	item: Advert.AdvertsResponse;
	handleNavigateTo: (item: Advert.AdvertResponse) => void;
}
const CarouselAll: React.FC<ProductsClassProps> = ({
	item: data = [],
	handleNavigateTo,
}) => {
	const [index, setIndex] = useState<number>(0);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 2,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	return (
		<>
			<StyleDotted />
			<Container dataLength={data.length}>
				<Carousel
					swipeable={true}
					draggable={false}
					responsive={responsive}
					ssr={true}
					infinite={data.length > 1}
					autoPlay={true}
					autoPlaySpeed={5000}
					keyBoardControl={true}
					transitionDuration={500}
					containerClass="carousel-container"
					removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
					showDots={data?.length > 1 ? true : false}
				>
					{data &&
						data.map((ad) => {
							return (
								<CarouselItem
									key={ad.id}
									item={ad}
									handleNavigateTo={handleNavigateTo}
								/>
							);
						})}
				</Carousel>
			</Container>
		</>
	);
};

export default CarouselAll;
