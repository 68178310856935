import { createContext } from "react";
import { CartItem } from "../models/cart";

import { Product as ProductService, Store } from '@inhouse-market/sdk';

interface CartContextProps {
	updateCartItem: (
		planogram: ProductService.PlanogramItem,
		quantity: number,
		redirect: boolean,
		clearCart: boolean,
		confirmRemoveItem: boolean,
		store?: Store.Store2,
	) => Promise<boolean>;
	clearCart: (isResetStore: boolean) => void;
	getCart: () => CartItem[];
	cartIds: number[];
	getStore: () => Store.Store2 | undefined;
	itemsTotal: number;
	isAlreadyCreated: boolean;
	setIsAlreadyCreated: (bool: boolean) => void;
	setStore: (value: Store.Store2) => void;
}

export const CartContext = createContext({} as CartContextProps);