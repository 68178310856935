import { useContext } from 'react';
import { CartContext } from '../contexts';

export const useCart = () => {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('useCart must be used within a CartContext');
  }

  return context;
};
