import { AntDesign } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect } from "react";
import { ScrollView } from "react-native";
import {
	ChangeButton,
	ConfirmButton,
	ContainerContentErro,
	ContentErro,
	ErroText,
	TextChange,
	TextConfirmButton,
	BtnContainer,
	BtnArea,
	LogoArea,
	Logo,
	NoticeText,
	Container
} from "./styles";
import HeaderNavigation from "../../components/HeaderNavigation";

const Result: React.FC = () => {
	const navigation = useNavigation();
	const { params } = useRoute();

	const success = React.useMemo(() => params?.success ?? false, [params]);

	const handleNavigateTo = () => {
		navigation.navigate("Loja");
	};

	const handleNavigateToComprovantePagamento = () => {
		navigation.navigate(
			"ComprovantePagamento" as never,
			{
				purchaseId: params.purchaseId,
			} as never
		);
	};

	const results = React.useMemo(() => {
		return !success
			? {
					icon: "closecircle",
					iconColor: "#FA3E25",
					title: "Desculpe ocorreu um erro ao carregar a página\nTente novamente mais tarde.",
					buttonText: "VOLTAR AO CARRINHO",
			  }
			: {
					icon: "checkcircle",
					iconColor: "#00BA00",
					title: "Obrigado, recebemos seu pagamento!",
					buttonText: "Ver comprovante",
			  };
	}, [success]);

	useEffect(() => {
		navigation.setOptions({tabBarStyle: {display: 'none'}});
	}, [])

	return (
		<Container>
		<HeaderNavigation title="Pagamento" enableButtonLeft enableButtonRight={false} />
		<ScrollView>
			<ContainerContentErro>
				<ContentErro>
					<AntDesign
						name={results.icon}
						size={147}
						color={results.iconColor}
					/>
					<ErroText>{results.title}</ErroText>
					<NoticeText>
					  Quer pagar com cartão de crédito? <br /> Baixe o app SHOPPBUD na app store
					</NoticeText>
					<LogoArea>
				  	<Logo source={require('../../../assets/shoppbud.svg')} />
				  </LogoArea>
				</ContentErro>
			
			</ContainerContentErro>
		</ScrollView>
		<BtnContainer>
			<BtnArea>
				<ChangeButton onPress={handleNavigateTo}>
						<TextChange>
							Voltar ao início
						</TextChange>
				</ChangeButton>
			</BtnArea>
			<BtnArea>
					<ConfirmButton onPress={handleNavigateToComprovantePagamento}>
							<TextConfirmButton>
									Ver comprovante
								</TextConfirmButton>
					</ConfirmButton>
					</BtnArea>
		</BtnContainer>
		</Container>
	);
};

export default Result;
