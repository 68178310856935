import styled from "styled-components/native";
import { Button } from "../../components/Button";
import { theme } from "../../global/theme";

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const ProofHeader = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 26px;
	margin-left: 20px;
	margin-right: 20px;
`

export const ProofHeaderNavigation = styled.Text`
  font-size: 15px; 
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.bold};
  text-decoration: underline;
  text-decoration-color:  ${theme.colors.primary};
`

export const Title = styled.Text`
	font-size: 18px;
  font-family: ${theme.fonts.bold};
  text-align: center;
  color: ${theme.colors.tertiary};
  flex: 4;
`;

export const HelpButton = styled.TouchableOpacity`
	flex: 1;
	min-width: 20%;
	margin-top: 5%;
	margin-bottom: 5%;
	justify-content: space-between;
	flex-direction: row;
`;
export const QrCodeContainer = styled.View`
	// flex: 1;
	justify-content: center;
	align-items: center;
	background-color: ${theme.colors.lightGray};
	padding-top: 48px;
`;

export const QrCodeContent = styled.View`
	background-color: ${theme.colors.white};
	padding: 12px;
`;

export const NoItemsText = styled.Text`
	font-size: 20px;
	text-align: center;
	flex: 1;
	padding-top: 20px;
	font-family: ${theme.fonts.regular};
	background-color: ${theme.colors.white};
`;

export const StoreContainer = styled.View`
	height: 90px;
	flex-direction: row;
	align-items: center;
	padding-horizontal: 20px;
	background-color: ${theme.colors.white};
`;

export const StoreImage = styled.Image`
	width: 70px;
	height: 70px;
	margin-right: 8px;
	border-radius: 7px;
`;

export const StoreDetailsContainer = styled.View`
	flex: 1;
`;

export const StoreName = styled.Text`
	font-size: 18px;
	font-family: ${theme.fonts.bold};
	padding-right: 38px;
	color: ${theme.colors.tertiary};
`;

export const StoreLocation = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.regular};
`;

export const TotalContainer = styled.View`
	flex-direction: row;
	justify-content: space-between;
	padding-horizontal: 20px;
	padding-vertical: 18px;
	margin-top: 8px;
	background-color: ${theme.colors.white};
	border-bottom-width: 0.5px;
	border-top-width: 0.5px;
	border-color: #DADADA;
`;

export const Separator = styled.View`
	width: 100%;
	height: 10px;
	background-color: ${theme.colors.lightGray};
`;

export const DisabledView = styled.View<{ isCanceled: boolean }>`
	opacity: ${({ isCanceled }) => (isCanceled ? 0.5 : 1)};
`;

export const TotalText = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.regular};
`;

export const TotalValue = styled.Text`
	font-size: 18px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.secondary};
`;

export const ItemsScroll = styled.ScrollView`
	background-color: ${theme.colors.white};
`;

interface LineContainerProps {
	first?: boolean;
	isLast?: boolean;
}

export const LineContainer = styled.View<LineContainerProps>`
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	margin-horizontal: 20px;
	margin-vertical: 4px;
	${(props) =>
		!!props.first &&
		`
    margin-top: 20px;
  `}
	${props => props.isLast && `
		padding-bottom: 15px;
		border-bottom-width: 0.5px;
		border-color: #DADADA;
	`}

`;

export const ProofPaymentDateArea = styled.View`
	flex-direction: row;
	justify-content: space-between;
	margin: 0px 20px;
`

export const DetailsOrder = styled.View`
 margin: 0px 20px;
 justify-content: space-between;
 flex-direction: row;
 padding: 15px 0px;
`

export const ProofPaymentDate = styled.Text`
 font-size: 14px;
 font-family: ${theme.fonts.regular};
`

export const ProofPaymentStatus = styled.Text`
 font-size: 14px;
 color: ${theme.colors.green};
 font-family: ${theme.fonts.medium};
`


export const LineText = styled.Text`
	color: ${theme.colors.gray} ;
	font-family: ${theme.fonts.medium};
	font-size: 14px;
`;

export const ButtonsContainer = styled.View`
	padding-vertical: 15px;
	padding-horizontal: 20px;
	border-top-width: 1px;
	border-top-color: #DADADA;
	background-color: ${theme.colors.white};
`;

export const ScanQRCodeButton = styled(Button)`
	margin-bottom: 10px;
`;

export const PaymentTitle = styled.Text`
	font-size: 16px;
	padding-left: 20px;
	padding-vertical: 16px;
	font-family: ${theme.fonts.bold};
	border-top-width: 0.5px;
	border-top-color: #dadada;
`;

export const PaymentLineContainer = styled.View`
	padding-horizontal: 20px;
	padding-vertical: 24px;
	flex-direction: row;
	border-top-width: 0.5px;
	border-bottom-width: 0.5px;
	align-items: center;
	border-color: #DADADA;
`;

export const PaymentDetailsContainer = styled.View`
	flex: 1;
	margin-horizontal: 10px;
`;

export const PaymentDetailsTitle = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.gray};
`;

export const PaymentDetailsDescription = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.tertiary};
`;

export const PaymentChangeText = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.primary};
`;

export const MinorError = styled.Text`
	font-size: 18px;
	text-align: center;
	width: 70%;
	margin: auto;
	font-family: ${theme.fonts.regular};
`;

export const ShareContainer = styled.View`
	bottom: 38px;
	right: 21px;
	width: 58px;
	height: 58px;
	border-radius: 50%;
	background-color: ${theme.colors.primary};
`;

export const ContentButtonProducts = styled.View`
	width: 100%;
	height: 92px;
	justify-content: center;
	align-items: center;
	background: #fff;
	border-top-width: 1px;
	border-color: #EDEDED;
	position: relative;
`;
export const ConfirmButton = styled.TouchableOpacity`
	display: flex;
	max-width: 335px;
	width: 80%;
	height: 55px;
	border-radius: 10px;
	background-color: ${theme.colors.primary};
	justify-content: center;
`;
export const TextConfirmButton = styled.Text`
	font-size: 18px;
	font-family: ${theme.fonts.semiBold};
	color: ${theme.colors.white};
	text-align: center;
`;

export const ContainerQRCode = styled.View`
	width: 100%;
	align-items: center;
	padding: 89px 0px 168px 0px;
	background-color: ${theme.colors.lightGray};
`;

export const TextDetails = styled.Text`
	margin-top: 20px;
	color: ${theme.colors.gray};
	text-align: center;
`;

export const ContainerHelp = styled.TouchableOpacity`
	flex-direction: row;
	padding: 5px;
	margin-top: 20px;
	align-items: center;
`;

export const Help = styled.Text`
	color: #ff7700;
	margin-left: 10px;
`;

export const LineArea = styled.View`
		border-top-width: 0.5px;
		border-top-color: #DADADA;
`
export const ShareProof = styled.TouchableOpacity`
	width: 58px;
	height: 58px;
	border-radius: 50%;
	background-color: ${theme.colors.primary};
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 27px;
	top: -106px;
`
export const IconProof = styled.Image`
  width: 27px;
	height: 28px;
`