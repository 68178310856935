import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';

const firebaseConfig = {
	apiKey: "AIzaSyBScpYFq6kbsez-IJ-ekNRmFRXhBjHJxtE",
	authDomain: "shoppbud-121f9.firebaseapp.com",
	projectId: "shoppbud-121f9",
	storageBucket: "shoppbud-121f9.appspot.com",
	messagingSenderId: "1078648941114",
	appId: "1:1078648941114:web:de1c5274994ce6c8ad2303",
	measurementId: "G-1F10588C0H",
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 1 * 60 * 1000;

fetchAndActivate(remoteConfig).then();

export async function requestToken() {
	try {
		const isSupportedBrowser = await isSupported();

		if (isSupportedBrowser) {
			const permission = await Notification.requestPermission();
			if (permission === "granted") {
				return await getToken(messaging, {
					vapidKey:
						"BBZQ3NftuYqCZ8wlIFVDsTdNqXVEPtIgVVs3aGX_Hs2OHNvra_lMom5MKrEhJC-RkGR_7Azn6Uvuv7dwgihgnGU",
				});
			} else {
				return "";
			}
		} else {
			return "";
		}
	} catch {
		return "";
	}
}
