import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Scroll = styled.ScrollView``;

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const Content = styled.View`
	flex: 1;
	padding: 20px;
`;

export const PaymentDetailsContainer = styled.View`
	background-color: ${theme.colors.white};
	align-items: center;
	justify-content: space-between;
	padding: 20px 54px;
	margin-bottom: 4px;
`;

export const PixValueContainer = styled.View`
	margin-bottom: 26px;
`;

export const Title = styled.Text`
	text-align: center;
	font-size: 16px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.anotherGray};
`;
export const Value = styled.Text`
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.tertiary};
	font-size: 18px;
`;

export const ValueSecondary = styled.Text`
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.secondary};
	font-size: 20px;
`;

export const PayAtContainer = styled.View`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-radius: 5px;
	border-width: 1px;
	margin-bottom: 26px;
	margin-top: 15px;
	border-color: #ededed;
	padding-vertical: 8px;
	padding-horizontal: 10px;
`;

export const PixDetailsContainer = styled.View`
	display: flex;
	height: 40%;
	width: 100%;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	border-width: 1px;
	border-color: #ededed;
	margin-bottom: 26px;
	padding-top: 14px;
`;

export const PixLogo = styled.Image`
	margin-bottom: 24px;
	width: 80px;
	height: 80px;
`;

export const PixInfo = styled.Text`
	text-align: center;
	padding: 0 20px;
	color: ${theme.colors.tertiary};
	font-size: 14px;
	padding-bottom: 32px;
	font-family: ${theme.fonts.regular};
`;

export const PixCodeContainer = styled.View`
	width: 100%;
	border-style: solid;
	border-radius: 5px;
	border-width: 1px;
	border-color: #ededed;
	padding-vertical: 8px;
	padding-horizontal: 10px;
	margin-bottom: 16px;
`;

export const ButtonPaymentContainer = styled.View`
	width: 100%;
	margin-top: 40px;
	justify-content: space-between;
	align-items: center;
`;

export const Label = styled.Text`
	color: rgba(48, 55, 74, 0.35);
	font-size: 12px;
	font-family: ${theme.fonts.regular};
`;
export const PixCodeText = styled.Text.attrs({
	numberOfLines: 1,
	ellipsizeMode: "tail",
})`
	font-size: 16px;
	font-family: ${theme.fonts.regular};
`;

export const ConfirmButton = styled.TouchableOpacity`
	display: flex;
	width: 267px;
	height: 45px;
	border-radius: 10px;
	background-color: ${theme.colors.primary};
	justify-content: center;
`;

export const TextConfirmButton = styled.Text`
	font-size: 16px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.white};
	text-align: center;
`;

export const TextAnother = styled.Text`
	font-size: 11px;
	font-family: ${theme.fonts.regular};
	color: #000000;
	margin-top: 10px;
`;

export const ChangeButton = styled.TouchableOpacity`
	display: flex;
	justify-content: center;
	width: 170px;
	height: 35px;
	box-sizing: border-box;
	background: ${theme.colors.white};
	border: 1px solid ${theme.colors.primary};
	border-radius: 10px;
	margin-top: 10px;
`;

export const TextChange = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.primary};
	text-align: center;
`;

export const ContainerContentEmptyCart = styled.View`
	display: flex;
	height: 80%;
	align-items: center;
	justify-content: space-between;
`;

export const ContentEmptyCart = styled.View`
	display: flex;
	height: 80%;
	align-items: center;
	justify-content: space-evenly;
`;

export const EmptyCartContainer = styled.View`
	width: 100%;
	height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const EmptyCartIcon = styled.Image`
	width: 210px;
	height: 210px;
`;

export const NoItemsText = styled.Text`
	width: 317px;
	font-size: 18px;
	text-align: center;
	padding-top: 20px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.black};
`;

export const ContentButtonProducts = styled.View`
	margin-top: 80px;
`;