import { createContext } from 'react';

interface NotificationBarProps {
  notify: (message: string, time?: number) => void;
  show: boolean;
  message: string;
}

export const NotificationBarContext = createContext<
  NotificationBarProps | undefined
>(undefined);
