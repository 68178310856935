import React, { useEffect, useMemo, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { FlashList } from '@shopify/flash-list';
import { Searchbar } from "react-native-paper";
import { useDebouncedCallback } from "use-debounce";

import { theme } from "../../global/theme";
import { useStore as useStoreHook } from "../../hooks/use-store";
import useStore from '../../store/store/useStore';
import useStores from "../../store/store/useStores";
import { Store } from "../../services/apiSDK";
import { BASE_URL } from "../../utils/contants";
import {
	Address,
	Box,
	Container,
	ContainerContentList,
	ContainerIconAlert,
	ContainerOpacity,
	Content,
	InfoView,
	LinkToStore,
	SeeMoreSection,
	StoreDetails,
	StoreLogo,
	StoreTitle,
	TextApartmentComplex,
} from "./styles";
import { SimpleLineIcons } from "@expo/vector-icons";
import { useCart } from "../../hooks/use-cart";
import { lockStores } from "../../utils/idsLocksStores";

interface HeaderProps {
	title?: string;
	hideBackBtn?: boolean;
	backgroundColor?: string;
	isTitleCentered?: boolean;
	visibleHeader?: boolean;
  hideModalAndShowHeader?: () => void;
}

export const Header: React.FC<HeaderProps> = ({ visibleHeader, hideModalAndShowHeader }) => {
	const logoIcon = require("../../../assets/logoHeader.png");
	const paramStore = useStoreHook();
	const cart = useCart();
	const navigation = useNavigation();
	const [checkedStore, setCheckedStore] = useState<Store.Store2 | null>(null);
	const [searchBarOpen, setSearchBarOpen] = useState(false);
	const [searchStore, setSearchStore] = useState("");
	const [searchQuery, setSearchQuery] = useState("");

	const {
		data: store,
		isLoading: isLoadingStore,
		isError: isErrorStore,
		isSuccess: isSuccessStore,
	} = useStore(Number(paramStore.storeId));

	const {
		data: stores,
		fetchNextPage: fetchNextPageStores,
		hasNextPage: hasNextPageStores,
		isFetching: isFetchingStores,
	} = useStores(			
		Number(paramStore.currentLatitude),
		Number(paramStore.currentLongitude),
		searchQuery,
	);

	const flattenedStores = useMemo<Store.Store[]>(() => {
		if (!stores) {
			return []
		}
		
		const data = stores?.pages.flatMap((page) => page.data) || [];

		const unlockedStores = data.filter((item) => !lockStores.includes(item?.id));

		return unlockedStores;
	}, [stores]);

	const delayingApiCall = useDebouncedCallback(
		(storeName: string) => setSearchQuery(storeName),
		500,
	);

	useEffect(() => {
		if (!isLoadingStore) {
			if (isErrorStore || isSuccessStore) {
				try {
					if (!store?.active_pwa) {
						throw new Error("Store not found");
					}
					if (store && lockStores.includes(store?.id)) {
						throw new Error("Store not found");
					}
					setCheckedStore(store)
					cart.setStore(store)
				} catch (error: any) {
					paramStore.setStore("")
					//remove querys
					if (hideModalAndShowHeader) {
						hideModalAndShowHeader()
					}
				}
			}
		}
	}, [store, isLoadingStore, isSuccessStore, isErrorStore]);

	useEffect(() => {
		const checkHeaderOpen = async () => {
			try {
				const headerOpen = await AsyncStorage.getItem("@openHeader");
				if (headerOpen === "true" || visibleHeader === true) {
					setSearchBarOpen(true);
				}
			} catch (error) {
				console.error("Error reading AsyncStorage:", error);
			}
		};

		checkHeaderOpen();
	}, [visibleHeader]);

	const handleNavigateToSupport = () => {
		navigation.navigate("Support"); //aqui
	};

	const handleLoadMoreStores = () => {
		if (hasNextPageStores && !isFetchingStores) {
			fetchNextPageStores();
		}
	}

	const renderItem = ({item}: {item: Store.Store}) => {
		return (
			<ContainerContentList key={item.id}>
				<LinkToStore
					href={`${BASE_URL}?store=${item.id}`}
				>
					<TextApartmentComplex>
						{item.name}
					</TextApartmentComplex>
				</LinkToStore>
			</ContainerContentList>
		)
	}

	return (
		<>
			<Container backgroundColor={theme.colors.tertiary}>
				<Content>
					<Box size={0.8} alignItems="center">
						<StoreLogo
							key={checkedStore?.id}
							source={ { uri: checkedStore?.logo } }
							testID="logo"
						/>
						<InfoView>
							<StoreTitle
								numberOfLines={1}
								ellipsizeMode="tail"
								testID="name"
							>
								{checkedStore?.name}
							</StoreTitle>
							
								<Address testID="address">
									{checkedStore?.address?.street}
								</Address>
						</InfoView>
					</Box>

					<Box size={0.2}>
						<ContainerIconAlert onPress={handleNavigateToSupport}>
							<SimpleLineIcons
								name="question"
								size={24}
								color="white"
							/>
						</ContainerIconAlert>
					</Box>
				</Content>
			</Container>
			{searchBarOpen && (
				<SeeMoreSection>
					<StoreDetails testID="details">
						<Searchbar
							placeholder="Digite o nome da unidade"
							onChangeText={(text) => {
								delayingApiCall(text)
								setSearchStore(text)
							}}
							value={searchStore}
							style={{
								borderRadius: 10,
								fontFamily: theme.fonts.regular,
								fontSize: 15,
								marginBottom: 20,
							}}
						/>
					<FlashList
						data={flattenedStores}
						estimatedItemSize={60}
						keyExtractor={(item, index) => `${index}-${item.id}`}
						renderItem={renderItem}
						onEndReached={handleLoadMoreStores}
						onEndReachedThreshold={0.5}
						showsVerticalScrollIndicator
					/>
					</StoreDetails>
				</SeeMoreSection>
			)}
			<ContainerOpacity
				style={{
					display: searchBarOpen === true ? "flex" : "none",
				}}
			/>
		</>
	);
};
