import { Feather } from "@expo/vector-icons";
import { Animated } from "react-native";
import styled from "styled-components/native";
import { theme } from "../../global/theme";

interface TypeProps {
	type: "default" | "compact" | "full";
}

export const Container = styled(Animated.View)<TypeProps>`
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
	padding-horizontal: 5px;
	height: 45px;
	overflow: hidden;
	border: 1px solid ${theme.colors.border};
	background-color: ${theme.colors.white};
	${(props) =>
		props.type === "compact" &&
		`
    height: 44px;
    border-radius: 22px;
    position: absolute;
    bottom: 9px;
    right: 8px;
    `}
`;

export const Minus = styled(Feather).attrs({
	name: "minus",
	size: 15,
})`
	padding: 10px;
`;

export const QuantityText = styled.Text`
	text-align: center;
	font-family: ${theme.fonts.bold};
	font-size: 14px;
	width: 30px;
`;

interface PlusProps {
	open: boolean;
}

export const Plus = styled(Feather).attrs({
	name: "plus",
	size: 15,
})<PlusProps>`
	${(props) =>
		props.open
			? `
    padding: 10px;
    `
			: `
    padding: 9px;
    `}
`;
