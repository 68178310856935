import { useInfiniteQuery } from '@tanstack/react-query';

import api from '../../services/apiSDK';
import { queryKeys } from '../queryClient';

export default function useStores(latitude: number, longitude: number, name?: string) {

  const queryFn = async ({ pageParam = 1 }: { pageParam?: number }) => {
    const { data: stores, meta } = await api.store.search({
			distance: 9999999999999,
			type: 'pwa',
			latitude,
			longitude,
      name,
      page: pageParam,
      perPage: 10,
      order: 'name'
		})

    return {
      data: stores || [],
      meta,
    };
  };

  return useInfiniteQuery({
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.current_page < lastPage.meta.last_page) {
        return lastPage.meta.current_page + 1;
      }
      return undefined;
    },
    queryFn,
    queryKey: queryKeys.STORES(latitude, longitude, name),
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
