import { Dimensions } from "react-native";
import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
	position: relative;
`;

export const Content = styled.ScrollView`
	flex: 1;
	margin: 20px 20px 0;
`;

export const AdWrapper = styled.View`
	border-width: 1px;
	border-color: #dadada;
	border-radius: 10px;
	padding: 5px;
`;
export const AdWrapperImage = styled.View`
	justify-content: center;
	align-items: center;
`;
export const Image = styled.Image`
	width: 100%;
	height: 168px;
	border-radius: 10px;
	border-color: #f6f6f6;
	border-width: 1px;
`;

export const Name = styled.Text`
	font-size: 18px;
	font-family: ${theme.fonts.medium};
	color: #30374a;
	padding: 16px;
	font-weight: 500px;
	text-align: left;
`;

export const Description = styled.Text`
	margin-top: 16px;
	font-size: 14px;
	font-family: ${theme.fonts.regular};
	color: #000000;
	padding: 10px;
	border-width: 1px;
	border-color: #dadada;
	border-radius: 10px;
	font-weight: 400px;
`;

export const Link = styled.TouchableOpacity`
	justify-content: center;
	align-items: center;
	height: 55px;
	padding: 20px 25px;
	background-color: ${theme.colors.primary};
	border-radius: 10px;
	margin: 10px 20px;
`;

export const LinkText = styled.Text`
	font-size: 18px;
	font-style: ${theme.fonts.semiBold};
	color: #fff;
`;

export const BackContainer = styled.TouchableOpacity`
	margin-top: 20px;
	margin-left: 20px;
`;

export const BackText = styled.Text`
	padding-bottom: 16px;
	font-size: 14px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.primary};
`;
