import React from "react";
import { useCart } from "../../hooks/use-cart";
import { CartItem as CartItemModel } from "../../models/cart";
import { Planogram } from "../../models/planogram";
import { NumberToCurrency } from "../../utils/numberToCurrency";
import { QuantityPicker } from "../QuantityPicker";
import {
	CartDetailsContainer,
	CartImageContainer,
	CartItemContainer,
	CartItemImage,
	CartItemName,
	CartItemPrice,
	CartPriceContainer,
	CartItemRow,
} from "./styles";

interface CartItemProps {
	item: CartItemModel;
	isLast: boolean;
	showNumberSelectedLabel?: boolean;
	action?: () => void;
}

export const CartItem: React.FC<CartItemProps> = ({
	item,
	showNumberSelectedLabel,
	isLast,
	action,
}) => {
	const myCart = useCart();

	const onChangeQuantity = (item: CartItemModel, qnty: number) => {
		myCart.updateCartItem(item as unknown as Planogram, qnty, false, false, false);
	};

	return (
		<CartItemContainer isLast={isLast}>
			<CartImageContainer
				onPress={action && action}
				testID={`cart_item_${item.id}`}
			>
				<CartItemImage
					resizeMode="contain"
					source={{ uri: item.imageUrl }}
					testID={`cart_item_${item.id}_image`}
				/>
			</CartImageContainer>
			<CartDetailsContainer>
				{showNumberSelectedLabel ? (
					<>
						<CartItemRow>
							<CartItemName
								numberOfLines={3}
								bold
								testID={`cart_item_${item.id}_name`}
							>
								{item.name}{" "}
							</CartItemName>
							<CartItemPrice
								numberOfLines={2}
								testID={`cart_item_${item.id}_qtd`}
							>
								{item.quantity > 1
									? `${item.quantity} unidades`
									: `${item.quantity} unidade`}
							</CartItemPrice>
						</CartItemRow>
					</>
				) : (
					<>
						<CartItemName
							numberOfLines={3}
							testID={`cart_item_${item.id}_name`}
						>
							{item.name}
						</CartItemName>
						<QuantityPicker
							style={{ marginRight: "auto" }}
							value={item.quantity}
							maxValue={item.quantity + 2}
							onChange={(value) =>
								onChangeQuantity(item, value - item.quantity)
							}
							testID={`${item.id}`}
						/>
					</>
				)}
			</CartDetailsContainer>
			<CartPriceContainer>
				<CartItemPrice testID={`cart_item_${item.id}_price`}>
					{NumberToCurrency(
						(item.final_price_in_cents * item.quantity) / 100
					)}
				</CartItemPrice>
			</CartPriceContainer>
		</CartItemContainer>
	);
};
