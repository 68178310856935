type Env  = "production" | "staging";

export const env = process.env.APP_ENV as Env || "production";

const endpointsByEnv = {
  production: {
    api: "https://api.shoppbud.com.br",
  },
  staging: {
    api: "https://api.staging.shoppbud.com.br",
  },
} as const;

export const endpoints = endpointsByEnv[env] || endpointsByEnv.production;