import React from "react";
import { View, Text } from "react-native";
import LottieView from "lottie-react-native";

import { Container } from "./styles";

export const LoadingPage: React.FC = () => {
	return (
		<View>
			<Text>Carregando...</Text>
		</View>
		// <Container testID="loading_page">
		//   <LottieView
		//     testID="view-lottie"
		//     source={require('../../../assets/loading.json')}
		//     autoPlay
		//     loop={true}
		//   />
		// </Container>
	);
};

export default LoadingPage;
