import styled from 'styled-components/native';
import { theme } from '../../global/theme';

export const CartItemContainer = styled.View<{isLast: boolean}>`
  flex-direction: row;
  padding-vertical: 24px;
  border-bottom-width: ${props => !props.isLast ? '0.5px' : '0px'};
  border-bottom-color: #DADADA;
`;

export const CartItemRow = styled.View`
  justify-content: center;
  align-items: flex-start;
  flex: 1;
`;

export const CartImageContainer = styled.Pressable`
  width: 86px;
  height: 86px;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  elevation: 1;
  border: 1px solid #ededed;
`;

export const CartItemImage = styled.Image`
  flex: 1;
`;

export const CartDetailsContainer = styled.View`
  flex: 1;
  justify-content: space-between;
  margin-right: 16px;
`;

interface CarCartItemNameProps {
  bold?: boolean;
}
export const CartItemName = styled.Text<CarCartItemNameProps>`
  font-size: 14px;
  font-family: ${theme.fonts.medium}
  color: ${theme.colors.tertiary}
`;

export const CartPriceContainer = styled.View`
  justify-content: center;
`;

export const DiscountCartItemPrice = styled.Text`
  font-size: 12px;
  text-decoration: line-through;
  text-align: center;
  flex: 1;
  font-family: ${theme.fonts.regular};
  color: ${theme.colors.gray};
`;

interface CartItemPriceProps {
  bold?: boolean;
}
export const CartItemPrice = styled.Text<CartItemPriceProps>`
  font-size: 18px;
  text-align: center;
  color: #000000;
  font-family: ${theme.fonts.semiBold};
`;

export const PriceContainerFiller = styled.View`
  flex: 1;
`;
