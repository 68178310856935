import React from "react";
import * as S from "./styles";
import { AntDesign } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import { Store } from "../../models/store";

interface ParamsStore {
	storeId: string, 
	currentLatitude: string, 
	currentLongitude: string
}

interface Props {
	params: ParamsStore;
	store: Store;
	hideModal: VoidFunction;
	openListApartment: VoidFunction;
	hideModalAndShowHeader: VoidFunction;
}

export const ModalInHouse: React.FC<Props> = ({
	params,
	store,
	hideModal,
	openListApartment,
	hideModalAndShowHeader,
}) => {
	if (params.storeId === '') {
		return (
			<S.Modal transparent>
				<S.Container>
					<S.ContainerContentModal>
						<AntDesign
							name="closecircle"
							size={120}
							color="red"
						/>
						<S.ContainerModalContent>
							<S.TextModalTitleError>
								Opss! Não identificamos a loja informada.
							</S.TextModalTitleError>
						</S.ContainerModalContent>
						<S.ContainerModalContentErrorBtn>
							<S.ConfirmButton onPress={hideModalAndShowHeader}>
								<S.TextConfirmButton>
									Selecionar loja
								</S.TextConfirmButton>
							</S.ConfirmButton>
						</S.ContainerModalContentErrorBtn>
					</S.ContainerContentModal>
				</S.Container>
			</S.Modal>
		);
	}
	return (
		<S.Modal transparent>
			<S.Container>
				<S.ContainerContentModal>
					<S.ContainerModalTitle>
						<Feather
							name="alert-triangle"
							size={24}
							color="#30374A"
						/>
						<S.TextModalTitle>ATENÇÃO</S.TextModalTitle>
					</S.ContainerModalTitle>
					<S.ContainerModalContent>
						<S.TextModalContent>
							Você está na unidade
						</S.TextModalContent>
						<S.TextModalContentBold>
							{store.name}
						</S.TextModalContentBold>
					</S.ContainerModalContent>
					<S.ContainerModalContentConfirmBtn>
						<S.ConfirmButton onPress={hideModal}>
							<S.TextConfirmButton>Continuar</S.TextConfirmButton>
						</S.ConfirmButton>
					</S.ContainerModalContentConfirmBtn>
					<S.ContainerModalContentTextBtn>
						<S.TextAnother>ou</S.TextAnother>
					</S.ContainerModalContentTextBtn>
					<S.ContainerModalContentChangeBtn>
						<S.ChangeButton
							onPress={() => {
								hideModal();
								openListApartment();
							}}
						>
							<S.TextChange>Altere de unidade</S.TextChange>
						</S.ChangeButton>
					</S.ContainerModalContentChangeBtn>
				</S.ContainerContentModal>
			</S.Container>
		</S.Modal>
	);
};
