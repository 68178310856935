import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const Content = styled.View`
	flex: 1;
	padding: 20px;
`;

export const PixValueContainer = styled.View`
	margin-bottom: 26px;
`;

export const Value = styled.Text`
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.black};
	font-size: 20px;
`;

export const ContainerRow = styled.TouchableOpacity<{ first?: boolean}>`
	flex-direction: row;
	align-items: center;
	border-bottom-width: 0.8px;
	padding: 18px 20px 18px 20px;
	border-bottom-color: #EDEDED;
	${props => props.first && `margin-top: 29px;`}
`;

export const ContainerRowDownload = styled.View`
	flex-direction: row;
	margin-top: 64px;
	width: 100%;
	justify-content: center;
`;

export const IconContainer = styled.View`
	margin-right: 25px;
`;

export const ImageContainer = styled.Image`
	width: 32px;
	height: 32px;
	margin-right: 25px;
`;

export const ImageMyBuys = styled.Image`
	width: 32px;
	height: 32px;
`;

export const TextButton = styled.Text`
	font-size: 16px
`;

export const TextDownload = styled(TextButton)`
	font-weight: 600;
`;

export const ContainerStores = styled.View`
	flex-direction: row;
	justify-content: space-evenly;
	margin-top: 25px;
	width: 80%;
	align-self: center;
`;

export const ContainerImageStore = styled.Image`
	width: 180px;
	height: 50px;
	border-radius: 6px;
`;

export const PressableImage = styled.TouchableOpacity``;

export const Version = styled.View`
	width: 100%;
	align-items: center;
	padding: 10px;
	flex: 1;
	justify-content: flex-end;
`;

export const VersionText = styled.Text`
	font-size: 12px;
	font-weight: bold;
	color: ${theme.colors.gray};
`;
