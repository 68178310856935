import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const Content = styled.View`
	flex: 1;
	padding: 20px;
`;

export const PixValueContainer = styled.View`
	margin-bottom: 26px;
`;

export const Value = styled.Text`
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.black};
	font-size: 20px;
`;

export const ContainerRow = styled.TouchableOpacity`
	flex-direction: row;
	margin-top: 20px;
	align-items: center;
`;

export const IconContainer = styled.View`
	margin-right: 25px;
`;

export const ImageContainer = styled.Image`
	width: 32px;
	height: 32px;
	margin-right: 25px;
`;

export const TextButton = styled.Text`
	font-size: 16px;
`;

export const ContainerStores = styled.View`
	flex-direction: row;
	justify-content: space-evenly;
	margin-top: 20px;
	width: 80%;
	align-self: center;
`;

export const ContainerImageStore = styled.Image`
	width: 180px;
	height: 50px;
	border-radius: 6px;
`;
