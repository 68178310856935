import React from "react";
import { SaleItems } from "../../../../models/purchases";
import { NumberToCurrency } from "../../../../utils/numberToCurrency";
import {
	CartDetailsContainer,
	CartImageContainer,
	CartItemContainer,
	CartItemName,
	CartItemPrice,
	CartPriceContainer,
	CartItemRow,
	TextUn,
	CartPrice,
} from "./styles";

interface CartItemProps {
	item: SaleItems;
	showNumberSelectedLabel?: boolean;
	action?: () => void;
	testID?: string;
	isLast: boolean
}

export const PurchaseItem: React.FC<CartItemProps> = ({
	item,
	showNumberSelectedLabel,
	action,
	testID,
	isLast
}) => {
	return (
		<CartItemContainer isLast={isLast}>
			<CartImageContainer
				onPress={action && action}
				testID={`item_action_${testID}`}
			>
				<TextUn>
					({item.quantity.toString().padStart(2, "0")} un)
				</TextUn>
			</CartImageContainer>
			<CartDetailsContainer>
				<CartItemRow>
					<CartItemName
						numberOfLines={3}
						bold
						testID={`item_name_${testID}`}
					>
						{item.name}{" "}
					</CartItemName>
					<CartItemPrice
						numberOfLines={2}
						testID={`item_qtd_${testID}`}
					>
						{NumberToCurrency(item.price / 100)} / un
					</CartItemPrice>
				</CartItemRow>
			</CartDetailsContainer>
			<CartPriceContainer>
				<CartPrice>
					{NumberToCurrency((item.price * item.quantity) / 100)}
				</CartPrice>
			</CartPriceContainer>
		</CartItemContainer>
	);
};

export default PurchaseItem;
