import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.View`
	margin-bottom: 25px;
	overflow: visible;
`;

export const Header = styled.View`
	flex-direction: row;
	justify-content: space-between;
	padding-right: 20px;
`;

export const SeeMoreButton = styled.TouchableOpacity``;

export const SeeMoreText = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.primary};
	text-decoration: underline;
`;

export const Title = styled.Text`
	padding-bottom: 14px;
	padding-left: 25px;
	font-size: 18px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.tertiary};
`;

export const ItemsHorizontalScroll = styled.ScrollView``;

export const ScrollInner = styled.View`
	padding-left: 25px;
	flex-direction: row;
`;
