import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const Content = styled.View`
	flex: 1;
`;
export const ErroIcon = styled.Image`
	width: 210px;
	height: 210px;
`;

export const ContainerResult = styled.View`
	display: flex;
	justify-content: center;
	flex-direction: row;
	margin-bottom: 94px;
	margin-top: 41px;
`

export const ButtomPrev = styled.TouchableOpacity`
  position: absolute;
	left: 0;
	top: 0;
	margin: 0px 20px;
`

export const PrevText = styled.Text`
	font-size: 15px; 
	color: ${theme.colors.primary};
	font-family: ${theme.fonts.bold};
	text-decoration: underline;
	text-decoration-color:  ${theme.colors.primary};
`

export const TitleScreen = styled.Text`
	font-size: 18px;
	color: ${theme.colors.tertiary};
	font-family: ${theme.fonts.bold};
`


export const ContainerContentErro = styled.View`
	display: flex;
	height: 80%;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	margin-top: 94px;
`;

export const ContentErro = styled.View`
	display: flex;
	height: 80%;
	align-items: center;
`;

export const ErroText = styled.Text`
  width: 100%;
	max-width: 404px;
	flex-wrap: wrap;
	font-size: 18px;
	text-align: center;
	padding-top: 20px;
	font-family: ${theme.fonts.semiBold};
	color: #000000;
`;


export const TextMessageSuccess = styled.Text`
	font-weight: ${theme.fonts.semiBold};
	font-size: 18px;
	color: #000000;
`;


export const BtnContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: 90px;
	padding-left: 19px;
	align-items: center;
	padding-right: 19px;
	position: fixed;
	bottom: 0px;
	background-color: #fff;
	border-top-width: 1px;
	border-color: #ededed;
`;

export const ChangeButton = styled.TouchableOpacity`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50px;
	box-sizing: border-box;
	background: ${theme.colors.white};
	border: 2px solid ${theme.colors.primary};
	border-radius: 10px;
	margin-right: 5px;
`;

export const TextChange = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.medium};
	color: ${theme.colors.primary};
	text-align: center;
`;

export const LogoArea = styled.View`
	width: 100%;
	justify-content: center;
	align-items: center;
`

export const Logo = styled.Image`
	width: 100%;
	max-width: 222px;
	height: 73px;
`

export const ConfirmButton = styled.TouchableOpacity`
	display: flex;
	width: 100%;
	height: 50px;
	margin-right: 5px;
	border-radius: 10px;
	background-color: ${theme.colors.primary};
	justify-content: center;
`;

export const TextConfirmButton = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.semiBold};
	color: ${theme.colors.white};
	text-align: center;
`;

export const BtnArea = styled.View`
 width: 100%;
 max-width: 49%;
`
export const NoticeText = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.regular};
	color: #000000;
	text-align: center;
	margin-top: 58px;
	line-height: 19.94px;
`