import React, { Dispatch, SetStateAction } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Modal as ReactNativeModal } from 'react-native';

import { Overlay, Container, Header, Content } from './styles';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  testID?: string;
  onClose?: () => void;
  children?: any
}

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  setIsOpen,
  testID,
  onClose,
}) => {
  return (
    <ReactNativeModal
      animationType="slide"
      transparent={true}
      visible={isOpen}
      onRequestClose={() => {
        onClose?.();
        setIsOpen(false);
      }}
      testID={testID}
    >
      <StatusBar backgroundColor="#000000cc" />
      <Overlay>
        <Container>
          <Header />
          <Content>{children}</Content>
        </Container>
      </Overlay>
    </ReactNativeModal>
  );
};

export default Modal;
