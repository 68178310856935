import { useQuery } from '@tanstack/react-query';

import api from '../../services/apiSDK';
import { queryKeys } from '../queryClient';

export default function useStore(storeId?: number) {
  const queryFn = async () => {
    if (!storeId) return null;

    const data = await api.store.get(storeId);
    return data
  };

  return useQuery({
    queryFn,
    queryKey: queryKeys.STORE_DETAILS(storeId || null),
    retry: false,
    staleTime: 0,
  });
}
