import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React from "react";
import { Image } from "react-native";
import { theme } from "../global/theme";
import Cart from "../pages/cart";
import Category from "../pages/category";
import Erro from "../pages/erro";
import Home from "../pages/home";
import Payment from "../pages/payment";
import PurchaseDetails2 from "../pages/purchaseDetails";
import Result from "../pages/result";
import SearchProducts from "../pages/searchProducts";
import Support from "../pages/support";
import { Ad } from "../pages/Ad";

import { useCart } from "../hooks/use-cart";
import Others from "../pages/others";
import { MyBuys } from "../pages/myBuys";
import Product from "../pages/Product";

const Tab = createBottomTabNavigator();

const storeIcon = require("../../assets/storeIcon.png");
const cartIcon = require("../../assets/cartIcon.png");
const paymentIcon = require("../../assets/pay.svg");
const menuIcon = require("../assets/menu.svg");

export default function Routes() {
	const itemsCount: number | any = useCart();

	return (
		<Tab.Navigator
			screenOptions={({ route }) => ({
				headerShown: false,
				tabBarLabelPosition: "below-icon",
				tabBarLabelStyle: {
					fontSize: 13,
					fontFamily: theme.fonts.bold,
					color: theme.colors.tertiary,
					marginBottom: 15,
				},
				tabBarIconStyle: {
					display: "flex",
					justifyContent: "center",
					height: "100%",
					marginTop: 10,
				},
				tabBarActiveTintColor: theme.colors.tertiary,
				tabBarInactiveTintColor: "#999DA6",
				tabBarStyle: { height: 80, zIndex: -1 },
			})}
		>
			<Tab.Screen
				options={{
					tabBarIcon: ({ size, focused, color }) => {
						return (
							<Image
								style={{
									width: size,
									height: size,
									tintColor: color,
								}}
								source={storeIcon}
							/>
						);
					},
				}}
				name="Loja"
				component={Home}
			/>
			<Tab.Screen
				options={{
					tabBarStyle: {display: 'none'},
					tabBarIcon: ({ size, focused, color }) => {
						return (
							<Image
								style={{
									width: size,
									height: size,
									tintColor: color,
								}}
								source={cartIcon}
							/>
						);
					},
					tabBarBadge:
						itemsCount.itemsTotal > 0
							? itemsCount.itemsTotal
							: null,
				}}
				name="Carrinho"
				component={Cart}
			/>
			<Tab.Screen
				options={{
					tabBarIcon: ({ size, focused, color }) => {
						return (
							<Image
								style={{
									width: size,
									height: size,
									tintColor: color,
								}}
								resizeMode="stretch"
								source={paymentIcon}
							/>
						);
					},
				}}
				name="Pagar"
				component={Payment}
			/>
			<Tab.Screen
				name="Result"
				component={Result}
				options={{
					tabBarItemStyle: {
						display: "none",
					},
				}}
			/>
			<Tab.Screen
				name="PesquisarProdutos"
				component={SearchProducts}
				options={{
					tabBarItemStyle: {
						display: "none",
					},
				}}
			/>
			{/* <Tab.Screen name="ComprovantePagamento" component={ProofPayement} />   */}
			<Tab.Screen
				name="ComprovantePagamento"
				component={PurchaseDetails2}
				options={{
					tabBarItemStyle: {
						display: "none",
					},
					unmountOnBlur: true,
				}}
			/>
			<Tab.Screen
				name="Erro"
				component={Erro}
				options={{
					tabBarItemStyle: {
						display: "none",
					},
				}}
			/>
			<Tab.Screen
				name="Categorias"
				component={Category}
				options={{
					tabBarItemStyle: {
						display: "none",
					},
					unmountOnBlur: true,
				}}
			/>
			<Tab.Screen
				name="Others"
				component={Others}
				options={{
					tabBarLabel: "Outros",
					tabBarIcon: ({ size, focused, color }) => {
						return (
							<Image
								style={{
									width: size,
									height: size,
									tintColor: color,
								}}
								resizeMode="stretch"
								source={menuIcon}
							/>
						);
					},
				}}
			/>
			<Tab.Screen
				name="MyBuys"
				component={MyBuys}
				options={{
					tabBarItemStyle: {
						display: "none",
					},
				}}
			/>
			<Tab.Screen
				name="Support"
				component={Support}
				options={{
					tabBarItemStyle: {
						display: "none",
					},
				}}
			/>
			<Tab.Screen
				name="Ad"
				component={Ad}
				options={{
					tabBarItemStyle: {
						display: "none",
					},
				}}
			/>
			<Tab.Screen
				name="Product"
				component={Product}
				options={{
					tabBarStyle: {display: 'none' },
					tabBarItemStyle: {
						display: "none",
					},
				}}
			/>
		</Tab.Navigator>
	);
}
