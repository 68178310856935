import styled from "styled-components/native";
import { theme } from "../../global/theme";


export const HeaderContainer = styled.View`
  margin: 0 19px;
  margin-top: 41px;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const HeaderButton = styled.TouchableOpacity<{ position: string }>`
  position: absolute;
  ${props => props.position === 'left' ? 'left: 0px' : 'right: 0px'};
`

export const HeaderLink = styled.Text`
  font-size: 15px; 
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.bold};
  text-decoration: underline;
  text-decoration-color:  ${theme.colors.primary};
`

export const HeaderTitle = styled.Text`
  font-size: 18px;
  font-family: ${theme.fonts.bold};
  text-align: center;
  color: ${theme.colors.tertiary};
`