import styled from "styled-components/native";

export const Overlay = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #000000cc;
`;

export const Container = styled.View`
  background-color: #ffffff;
  border-radius: 10px;
  width: 70%;
`;

export const Header = styled.View`
  align-items: flex-end;
  height: 30px;
`;

export const Content = styled.View``;
