import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { NumberToCurrency } from "../../utils/numberToCurrency";
import { QuantityPicker } from "../QuantityPicker";
import {
	Container,
	DescriptionContainer,
	DiscountIcon,
	Image,
	ImageContainer,
	RiskedPrice,
	PriceText,
	QuantityContainer,
	Title,
	ContainerMessage,
	Message,
} from "./styles";

const OfferIcon = require("../../../assets/offer.svg");

interface ProductProps {
	imgUrl: string;
	title: string;
	price_list: number;
	price_final: number;
	quantity: number;
	maxQuantity: number;
	onQuantityChange: (qnty: number) => void;
	quantityOpen?: boolean;
	onQuantityOpen: () => void;
	style?: StyleProp<ViewStyle>;
	onPress: () => void;
	testID?: string;
}

export const Product: React.FC<ProductProps> = ({
	imgUrl,
	title,
	price_list,
	price_final,
	quantity,
	maxQuantity,
	onQuantityChange,
	quantityOpen,
	onQuantityOpen,
	style,
	onPress,
	testID,
}) => {
	const inSale = price_final < price_list;

	return (
		<>
			<Container
				style={style}
				onPress={onPress}
				testID={`btn_product_${testID}`}
			>
				<ImageContainer>
					<Image
						source={{ uri: imgUrl }}
						testID={`product_${testID}_image`}
						resizeMode="stretch"
					/>
					{inSale && (
						<DiscountIcon
							testID={`product_${testID}_discount_icon`}
							source={OfferIcon}
						/>
					)}
					{maxQuantity <= 0 && (
						<ContainerMessage>
							<Message>
								Esse produto pode não estar em estoque
							</Message>
						</ContainerMessage>
					)}
				</ImageContainer>
				<DescriptionContainer>
					<Title
						numberOfLines={2}
						testID={`product_${testID}_title`}
					>
						{title}
					</Title>
					{inSale && (
						<RiskedPrice testID={`product_${testID}_old_price`}>
							{NumberToCurrency(price_list)}
						</RiskedPrice>
					)}
					<PriceText
						isDiscount={inSale}
						testID={`product_${testID}_price`}
					>
						{NumberToCurrency(price_final)}
					</PriceText>
					<QuantityContainer>
						<QuantityPicker
							type="full"
							open={quantityOpen}
							value={quantity}
							maxValue={quantity + 1}
							onChange={onQuantityChange}
							onOpen={onQuantityOpen}
							testID={testID}
						/>
					</QuantityContainer>
				</DescriptionContainer>
			</Container>
		</>
	);
};
