import styled from "styled-components/native";
import { theme } from "../../global/theme";



export const Container = styled.ScrollView`
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: #fff;
`

export const AlertArea = styled.View`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 36px;
`
export const AlertText = styled.Text`
  width: 190px;
  height: 40px;
  font-family: ${theme.fonts.regular}
  font-size: 16px;
`

export const AlertTextBold = styled.Text`
 font-family: ${theme.fonts.bold};
`

export const Header = styled.View`
  padding: 47px 0px 10px 20px;
  border: 0.5px solid #DADADA;
`

export const BackText = styled.Text`
  text-decoration: underline;
  font-size: 14px;
  font-family: ${theme.fonts.bold};
  color: ${theme.colors.primary};
  text-decoration-color: ${theme.colors.primary};
`;

export const WarningArea = styled.Text`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

export const WarningImage = styled.Image`
  width: 17px;
  height: 17px;
`

export const TextWarning = styled.Text`
  font-family: ${theme.fonts.regular};
  color: ${theme.colors.gray};
  font-size: 13px;
`

export const ScannerArea = styled.View`
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content:center;
  align-items: center;
`

export const ScannerAreaCenter = styled.View`
  width:100%;
  height: 100%;
  max-width: 384px;
  min-width: 300px;
  padding: 50px;
`

interface ScannerCornerProps {
  corner: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
}


export const ScannerCorner = styled.View<ScannerCornerProps>`
  position: absolute;
  width: 10%;
  height: 10%;
  ${(props) =>
    props.corner.startsWith('top')
      ? `
  top: 0;
  border-top-width: 3px;
  border-top.color: ${theme.colors.primary};
  `
      : `
  bottom: 0;
  border-bottom-width: 3px;
  border-bottom.color: ${theme.colors.primary};
  `}
  ${(props) =>
    props.corner.endsWith('Left')
      ? `
  left: 5.5%;
  border-left-width: 3px;
  border-left.color: ${theme.colors.primary};
  `
      : `
  right: 5.5%;
  border-right-width: 3px;
  border-right.color: ${theme.colors.primary};
  `}
`;

export const FooterScan = styled.View`
  padding: 0 64px 0 64px;
  margin: 40px 0px 20px 0px;
`

export const FooterText = styled.Text`
    width: 198px;
    font-size: 12px;
    color: ${theme.colors.white}
    font-family: ${theme.fonts.semiBold}
    text-align: center;
`