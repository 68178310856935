import { theme } from "./../../../global/theme";
import { Feather } from "@expo/vector-icons";
import { Animated } from "react-native";
import styled from "styled-components/native";

interface ContainerProps {
	show: boolean;
}

export const Container = styled(Animated.View)<ContainerProps>`
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	overflow: hidden;
	height: ${(props) => (props.show ? "100%" : "0px")};
	align-items: center;
	justify-content: center;
`;

export const ContainerPress = styled.TouchableOpacity`
	flex: 1;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const Panel = styled(Animated.View).attrs({
	onStartShouldSetResponderCapture: () => true,
})`
	width: 100%;
	border-radius: 16px;
	background-color: ${theme.colors.white};
`;

export const Content = styled.View.attrs({
	onStartShouldSetResponder: () => true,
	onStartShouldSetResponderCapture: () => true,
})`
	padding: 10px;
`;
