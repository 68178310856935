import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.TouchableOpacity<any>`
	width: 159px;
	border-radius: 10px;
	border: 1px solid ${theme.colors.border};
`;

export const ImageContainer = styled.View`
	height: 159px;
	width: 100%;
	position: relative;
`;

export const ImageContent = styled.View`
	justify-content: center;
	align-items: center;
	flex: 1;
`;

export const Image = styled.Image`
	flex: 1;
`;

export const DiscountIcon = styled.Image`
	position: absolute;
	top: 8px;
	left: 8px;
	width: 20px;
	height: 20px;
	fill: ${theme.colors.primary};
`;

export const QuantityContainer = styled.View.attrs({
	onStartSetSholderResponder: () => true,
})`
	position: absolute;
	bottom: 4px;
	right: 0;
`;

export const DescriptionContainer = styled.View`
	height: 85px;
	border-top-width: 1px;
	padding: 8px;
	position: relative;
	border-top-color: ${theme.colors.border};
`;

export const Title = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.regular};
`;

interface PriceTextProps {
	isDiscount: boolean;
}

export const PriceText = styled.Text<PriceTextProps>`
	position: absolute;
	bottom: 8px;
	right: 8px;
	font-size: 16px;
	font-family: ${theme.fonts.semiBold};
	color: ${({ isDiscount }) =>
		isDiscount ? theme.colors.primary : theme.colors.black};
`;

export const RiskedPrice = styled.Text`
	font-size: 13px;
	position: absolute;
	left: 8px;
	bottom: 9px;
	text-decoration: line-through;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.gray};
`;

export const ContainerMessage = styled.View`
	position: absolute;
	padding: 5px;
	text-align: center;
	border-top-left-radius: 5px;
	background-color: red;
	border-top-right-radius: 5px;
`;

export const Message = styled.Text`
	color: white;
	font-size: 10px;
	font-weight: 500;
`;
