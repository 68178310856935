import React, { useMemo, useState } from "react";
import { Searchbar } from "react-native-paper";
import { FlatList, View } from "react-native";
import { theme } from "../../global/theme";
import {
	Container,
	Content,
	ContainerTitle,
	ContentTitle,
	BackText,
	Title,
	ContainerContentEmptyCart,
	ContentEmptyCart,
	EmptyCartContainer,
	EmptyCartIcon,
	NoItemsText,
	Results,
	BtnContainer,
	ConfirmButton,
	TextConfirmButton,
	ChangeButton,
	TextChange,
	ContentItemBackText,
	ContentItemTitleText,
	ContainerProduct,
	TextOr,
} from "./styles";
import { AntDesign } from "@expo/vector-icons";
import { useCart } from "../../hooks/use-cart";
import { Product } from "../../components/ProductInList";
import api from "../../services/api";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useStore } from "../../hooks/use-store";
import { useDebouncedCallback } from "use-debounce";

import { Product as ProductService } from '@inhouse-market/sdk';

const loupeIcon = require("../../../assets/loupeSearch.png");

type Navigation = NavigationProp<any>;

type PlanogramPaginated = {
	data: ProductService.PlanogramItem[]
}

const SearchProducts: React.FC = () => {
	const [searchQuery, setSearchQuery] = useState("");
	const [resultProducts, setResultProducts] = useState<ProductService.PlanogramItem[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<ProductService.PlanogramItem>();

	const cart = useCart();
	const cartItems = useMemo(() => cart.getCart(), [cart]);
	const navigation = useNavigation<Navigation>();
	const paramStore = useStore();

	const handleChangeQuantity = (product: ProductService.PlanogramItem, qnty: number) => {
		const cartItem = cartItems.find((item) => item.id === product.id);
		const actualQnty = !cartItem ? 1 : qnty - cartItem.quantity;
		cart.updateCartItem(product, actualQnty, false, false, false);
	};

	const handleSubmit = async (term: string) => {
		try {
			if (term.length === 0) return;

			const storeId = paramStore.storeId;

			if (!storeId) return;

			const { data: PlanogramItems } = await api.get<PlanogramPaginated>(
				`client/store/${storeId}/planogram/search-paginated/${term}`
			);
			setResultProducts(PlanogramItems.data);
		} catch (error) {
			console.log(error);
		}
	};

	const searchProductSleep = useDebouncedCallback((text: string) => handleSubmit(searchQuery), 800);


	const handleNavigateToCart = () => {
		navigation.navigate("Carrinho");
	};

	const handleNavigateToHome = () => {
		navigation.navigate("Loja");
	};

	const goBack = () => {
		navigation.goBack();
	};

	const handleNavigateToScreen = (screenName: string, params: Object | undefined = undefined,) => {
		navigation.navigate(screenName, {
			params,
		});
	};

	const renderItemProduct = ({ item }: any) => (
		<ContainerProduct>
			<Product
				key={item.id}
				title={item?.product.name}
				imgUrl={item?.product?.image_url}
				price_list={parseFloat(item?.price_list_in_cents) / 100}
				quantity={
					cartItems.find((cartItem) => cartItem.id === item?.id)
						?.quantity || 0
				}
				maxQuantity={item.stock_amount}
				onQuantityChange={(qtd) => handleChangeQuantity(item, qtd)}
				quantityOpen={selectedProduct?.id === item.id}
				onQuantityOpen={() => setSelectedProduct(item)}
				price_final={parseFloat(item.price_final_in_cents) / 100}
				style={{
					marginBottom: 20,
				}}
				testID={item.id.toString()}
				onPress={() => handleNavigateToScreen('Product', { product: item })}
			/>
		</ContainerProduct>
	);

	return (
		<Container>
			<Content>
				<ContainerTitle>
					<ContentItemBackText onPress={goBack}>
						<BackText>Voltar</BackText>
					</ContentItemBackText>
					<ContentItemTitleText>
						<Title>Pesquisar </Title>
					</ContentItemTitleText>
				</ContainerTitle>
				<Searchbar
					placeholder="Buscar produto"
					onChangeText={(text) => {
						setSearchQuery(text);
						searchProductSleep(text)
					}}
					value={searchQuery}
					autoFocus={true}
					style={{
						borderRadius: 10,
						fontFamily: theme.fonts.regular,
					}}
				/>
				{resultProducts.length === 0 ? (
					<EmptyCartContainer>
						<ContainerContentEmptyCart>
							<ContentEmptyCart>
								<View>
									<AntDesign
										name="search1"
										size={140}
										color="#30374A"
									/>
								</View>
								<NoItemsText>
									Digite o nome ou o código de barras do produto
								</NoItemsText>
							</ContentEmptyCart>
						</ContainerContentEmptyCart>
					</EmptyCartContainer>
				) : (
					<Results>
						<FlatList
							data={resultProducts}
							renderItem={renderItemProduct}
							numColumns={1}
						/>
						<BtnContainer>
							<>
								<ConfirmButton
									style={{
										backgroundColor:
											cartItems.length === 0
												? "#B0B0B0"
												: theme.colors.primary,
									}}
									disabled={
										cartItems.length === 0 ? true : false
									}
									onPress={handleNavigateToCart}
								>
									<TextConfirmButton>
										Ir para o Carrinho
									</TextConfirmButton>
								</ConfirmButton>
							</>
							<TextOr>Ou</TextOr>
							<>
								<ChangeButton onPress={handleNavigateToHome}>
									<TextChange>Continue comprando</TextChange>
								</ChangeButton>
							</>
						</BtnContainer>
					</Results>
				)}
			</Content>
		</Container>
	);
};

export default SearchProducts;
