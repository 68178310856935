const getFirstNineDigits = (cpf: string) =>
  cpf
    .slice(0, 9)
    .split('')
    .map((item: string) => Number(item));

const getLastNineDigitsBeforeSecondDigitChecker = (cpf: string) =>
  cpf
    .slice(1, 10)
    .split('')
    .map((item: string) => Number(item));

const handleRestOfSum = (rest: number) => (rest < 2 ? 0 : 11 - rest);

const sumOf = (numArray: number[]) =>
  numArray.reduce(
    (acc: number, number: number, index: number) => acc + number * (10 - index),
    0,
  );

const getFirstDigitChecker = (cpf: string) =>
  handleRestOfSum(sumOf(getFirstNineDigits(cpf)) % 11);

const getSecondDigitChecker = (cpf: string) =>
  handleRestOfSum(sumOf(getLastNineDigitsBeforeSecondDigitChecker(cpf)) % 11);

export const isValidCPF = (cpf: string) => {
  cpf = cpf.toString()
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.match(/(\d)\1{10}/)) return false;

  return (
    cpf.slice(-2) ===
    `${getFirstDigitChecker(cpf)}${getSecondDigitChecker(cpf)}`
  );
};

export const formatCPF = (value: string) => {
  const cleanedValue = value.replace(/\D/g, '');
  const formattedCPF = cleanedValue
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

  return formattedCPF;
};
