import React, { Dispatch, SetStateAction } from 'react';
import { Linking, Pressable } from 'react-native';
import Modal from '../Modal';
const Warning = require("../../../assets/error.png");
const Success = require("../../../assets/checked.png")
const Alert = require("../../../assets/info.svg")


import {
  Container,
  Content,
  Title,
  Description,
  Actions,
  TextButton,
  IconAlert,
  IconContaine,
} from './styles';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description: string;
  action: VoidFunction;
  type: 'ERROR' | 'SUCCESS' | 'ALERT';
  testID?: string;
  onTimeClose: boolean;
}

export const ModalStatusLock: React.FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  title,
  description,
  action,
  testID,
  type,
  onTimeClose,
}) => {
  const modalAction = () => {
    action();
    setIsOpen(false);
  };

  const redirectToSupport = () => {
    Linking.openURL('https://api.whatsapp.com/send?phone=+558521806454&text=Ol%C3%A1%20preciso%20de%20ajuda.')
  }

  const onOpenSuccess = React.useCallback(() => {
    if (type == 'SUCCESS' && isOpen && onTimeClose) {
      setTimeout(() => setIsOpen(false), 1200);
    }
  }, [type, isOpen]);

  React.useEffect(() => {
    onOpenSuccess();
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      testID={testID}
      onClose={() => setIsOpen(false)}
    >
      <Container>
        <IconContaine>
          {type === 'ERROR' && <IconAlert source={Warning} resizeMode="contain" />}
          {type === 'SUCCESS' && <IconAlert source={Success} resizeMode="contain" />}
          {type === 'ALERT' && <IconAlert source={Alert} resizeMode="contain" />}
        </IconContaine>
        <Content>
          <Title testID="modal_title">{title}</Title>
          <Description testID="modal_description">{description}</Description>
        </Content>

        <Actions>
          {(type === 'ERROR' || type === 'ALERT') &&
            <Pressable
              onPress={redirectToSupport}
              style={{
                width: '50%',
                padding: 20,
                alignItems: 'center',
                justifyContent: 'center',
                borderRightWidth: 1,
                borderRightColor: '#949494',
              }}
              testID="modal_cancel_btn"
            >
              <TextButton>SUPORTE</TextButton>
            </Pressable>
          }
          <Pressable
            onPress={modalAction}
            style={{
              width: type === 'ERROR' || type === 'ALERT' ? '50%' : '100%',
              padding: 20,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            testID="modal_ok_button"
          >
            <TextButton>OK</TextButton>
          </Pressable>
        </Actions>
      </Container>
    </Modal>
  );
};

export default ModalStatusLock;
