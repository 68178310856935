import { useInfiniteQuery } from '@tanstack/react-query';

import api from "../../services/apiSDK";
import { queryKeys } from '../queryClient';

const perPage = 20;

export const buildUrl = (categoryName: string) => {
  switch (categoryName) {
    case 'OFERTAS':
      return api.product.soldout;
    case 'COMPRE DE NOVO':
      return api.product.buyagain;
    default:
      return api.product.productByCategory;
  }
};

export default function useProducts(storeId: number, categoryId?: number, name?: string) {
  const queryFn = async ({ pageParam = 1 }: { pageParam?: number }) => {
    if (!categoryId || !name) {
      return {
        data: [], meta: {
          current_page: 0,
          last_page: 0,
          per_page: 0,
          total: 0,
        }
      };
    }
    const { data, meta } = await buildUrl(name)({
      categoryId,
      page: pageParam,
      perPage,
      storeId,
    });

    return {
      data,
      meta,
    };
  };

  return useInfiniteQuery({
    enabled: !!storeId,
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.current_page === lastPage.meta.last_page) {
        return undefined;
      }
      return lastPage.meta.current_page + 1;
    },
    queryFn,
    queryKey: queryKeys.PRODUCTS(storeId, categoryId, name),
    cacheTime: 10 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
