import React from 'react';

import * as S from './style'
import { Button } from '../Button';
import {BarcodeScanner} from "./barcodeScanner"

interface ScanProps {
  setBarcodeOpened: React.Dispatch<React.SetStateAction<boolean>>
  onError: () => void
  onResult: (scan: string) => void
  barcodeOpened: boolean
}

const ScanBarcode: React.FC<ScanProps> = ({ setBarcodeOpened, onError: setError, onResult: setResult, barcodeOpened }) => {

  const onResult = (scan: string) => {
    setBarcodeOpened(() => false)
    setResult(scan)
  }

  const closeCam = () => {
    setBarcodeOpened(() => false)
  }

  return (
    <S.Container showsHorizontalScrollIndicator={false}>
      <S.Header>
        <S.BackText onPress={closeCam}>
          Voltar
        </S.BackText>
      </S.Header>
      <S.AlertArea>
        <S.AlertText>
          Aponte para o <S.AlertTextBold>código de barras</S.AlertTextBold> {"\n do produto"}
        </S.AlertText>
        <S.WarningArea>
          <S.WarningImage source={require('../../assets/info_gray.svg')} />
          <S.TextWarning>Não leve os produtos sem pagar</S.TextWarning>
        </S.WarningArea>
      </S.AlertArea>
      <S.ScannerArea>
        <S.ScannerAreaCenter>
          <BarcodeScanner
            setCode={(code) => {
              onResult(code)
            }} open={barcodeOpened}
            setOpen={setBarcodeOpened}
          />
        </S.ScannerAreaCenter>
        <S.ScannerCornerCenter />
        <S.ScannerCorner corner="topLeft" />
        <S.ScannerCorner corner="topRight" />
        <S.ScannerCorner corner="bottomLeft" />
        <S.ScannerCorner corner="bottomRight" />
      </S.ScannerArea>
      <S.FooterScan>
        <Button onPress={closeCam}>
          <S.FooterText>
            Voltar
          </S.FooterText>
        </Button>
      </S.FooterScan>
    </S.Container>
  );
}

export default ScanBarcode