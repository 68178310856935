import styledJS from "styled-components";
import styled from "styled-components/native";
import { theme } from "../../global/theme";

interface Props {
	selected: boolean;
}

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const TextChangeUnit = styled.Text`
	font-size: 10px;
	color: #fff;
	text-align: center;
	margin-bottom: 10px;
`;

export const PolygonIcon = styled.Image``;

export const Content = styled.View`
	flex: 1;
`;

export const CategoriesContainer = styled.ScrollView`
	margin-top: 17px;
`;

export const TitleCategories = styled.Text`
	padding-bottom: 14px;
	padding-left: 25px;
	font-size: 18px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.tertiary};
`;

export const CategoriesContainerContent = styled.ScrollView`
	padding-left: 25px;
	flex-direction: row;
	margin-bottom: 16.24px;
`;

export const CategoriesContent = styled.TouchableOpacity<Props>`
	padding: 10px 20px;
	border-radius: 30px;
	justify-content: center;
	margin-right: 14px;
	background-color: ${(props) =>
		props.selected ? theme.colors.primary : theme.colors.lightGray};
`;

export const TextCategories = styled.Text<Props>`
	font-size: 12px;
	font-family: ${theme.fonts.bold};
	color: ${(props) =>
		props.selected ? theme.colors.white : theme.colors.primary};
	text-align: center;
`;

export const EmptyStore = styled.View`
	flex: 1;
	align-items: center;
	justify-content: center;
	margin: 20px;
`;

export const EmptyMessage = styled.Text`
	color: ${theme.colors.gray};
	font-size: 14px;
`;


export const ContainerAreaSpaceButtonLock = styled.View`
	display: flex;
	position: absolute;
	align-items: center;
	bottom: 10px;
	left: 40px;
	right: 40px;
	border-radius: 10px;
	`;

export const ContainerAreaSpaceButton = styled.View`
  position: absolute;
  bottom: 92px;
  right: 21px;;
`

export const LockIcon = styled.Image`
	width: 15px;
	height: 22px;
	margin-right: 10px;
`

export const UnLockTitle = styled.Text`
		font-family: ${theme.fonts.semiBold};
		font-size: 18px;
`

export const CheckLogo = styled.Image`
	width: 147px;
	height: 147px;
	margin-bottom: 20px;
`;

export const ContainerContentList = styled.View`
	margin-top: 25px;
	width: 100%;
	height: 55px;
	border-bottom-width: 0.8px;
	border-bottom-color: #ededed;
`;

export const LinkToStore = styledJS.a`
	text-decoration: none;
`;

export const TextApartmentComplex = styled.Text`
	font-size: 16px;
	font-family: ${theme.fonts.medium};
	color: ${theme.colors.tertiary};
	text-decoration: none;
	text-decoration-style: none;
`;

export const TextApartmentComplexDistance = styled.Text`
	font-size: 11px;
	font-family: ${theme.fonts.medium};
	color: #999da6;
`;

export const ContainerListStores = styled.View`
	width: 100%;
	margin-bottom: 20px;
`;

export const Ad = styled.TouchableOpacity`
	position: relative;
	border-width: 1px;
	border-color: #ededed;
	border-radius: 10px;
	width: 159px;
	margin-right: 8px;
	overflow: hidden;
`;

export const ProductImage = styled.Image`
	height: 159px;
	width: 100%;
`;

export const ProductName = styled.Text`
	height: 72px;
	font-size: 11px;
	font-family: ${theme.fonts.medium};
	color: ${theme.colors.tertiary};
	font-weight: 500;
	border-top-width: 1px;
	border-top-color: #ededed;
	padding: 8px;
`;

export const UpContainer = styled.View`
  position: absolute;
  bottom: 196px;
  right: 21px;
`;

export const IconBarcode = styled.Image`
	width: 80px;
	height: 80px;
`;