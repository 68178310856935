import { useContext } from 'react';
import { NotificationBarContext } from '../contexts/notification-bar-context';

export function useNotificationBar() {
  const context = useContext(NotificationBarContext);

  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationContext',
    );
  }

  return context;
}
