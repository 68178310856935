import { Payment } from '@inhouse-market/sdk';
import { useMutation } from '@tanstack/react-query';

import api from '../../services/apiSDK';
import { queryKeys } from '../queryClient';

export default function usePurchase() {
  const mutationFn = async (purchaseOption: Payment.CreatePurchase) => {
    const data = await api.customer.payment.purchase(purchaseOption);
    
    return data
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.PURCHASE,
  });
}
