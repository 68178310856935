import React, { Dispatch, SetStateAction } from 'react';
import { Image }  from 'react-native';
import { Pressable } from 'react-native';
import Modal from '../Modal';
// import AlertIcon from '../../assets/info.svg';
// import SuccessIcon from '../../assets/success.svg';


import {
  Container,
  Content,
  Title,
  Description,
  Actions,
  TextButton,
} from './styles';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type?: 'info' | 'success';
  title: string;
  description: string;
  buttonConfirmationText?: string;
  action?: () => void;
  testID?: string;
}

export const ModalInfo: React.FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  type,
  title,
  description,
  buttonConfirmationText,
  action,
  testID,
}) => {
  const modalAction = () => {
    action && action();
    setIsOpen(false);
  };

  const AlertIcon = ('../../assets/info.svg');
  const SuccessIcon = ('../../assets/success.svg');
  
  
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      testID={testID}
      onClose={modalAction}
    >
     
      <Container>
        {/* {type && type === 'success' ?    <SuccessIcon /> : <AlertIcon />} */}
        <Content>
          <Title testID="modal_title">{title}</Title>
          <Description testID="modal_description">{description}</Description>
        </Content>

        <Actions>
          <Pressable
            onPress={modalAction}
            style={{
              width: '100%',
              padding: 20,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            testID="modal_ok_btn"
          >
            <TextButton>{buttonConfirmationText || 'OK'}</TextButton>
          </Pressable>
        </Actions>
      </Container>
    </Modal>
  );
};

export default ModalInfo;
