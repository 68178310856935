import React, { Dispatch, SetStateAction } from 'react';
import { Pressable } from 'react-native';
import Modal from '../Modal';
const AlertIcon = require("../../../assets/legal_age.svg");

import {
  Container,
  Content,
  Title,
  Description,
  Actions,
  TextButton,
  IconAlert,
  IconContaine,
} from './styles';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  description: string;
  action: VoidFunction;
  testID?: string;
}

export const ModalAgeRestricted: React.FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  title,
  description,
  action,
  testID,
}) => {
  const modalAction = () => {
    action();
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      testID={testID}
      onClose={() => setIsOpen(false)}
    >
      <Container>
        <IconContaine>
          <IconAlert source={AlertIcon} resizeMode="contain"/>
        </IconContaine>
        <Content>
          <Title testID="modal_title">{title}</Title>
          <Description testID="modal_description">{description}</Description>
        </Content>

        <Actions>
          <Pressable
            onPress={() => setIsOpen(false)}
            style={{
              width: '50%',
              padding: 20,
              alignItems: 'center',
              justifyContent: 'center',
              borderRightWidth: 1,
              borderRightColor: '#949494',
            }}
            testID="modal_cancel_btn"
          >
            <TextButton>CANCELAR</TextButton>
          </Pressable>
          <Pressable
            onPress={modalAction}
            style={{
              width: '50%',
              padding: 20,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            testID="modal_ok_button"
          >
            <TextButton>SIM</TextButton>
          </Pressable>
        </Actions>
      </Container>
    </Modal>
  );
};

export default ModalAgeRestricted;
