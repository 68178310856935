import React from 'react';
import { Linking } from 'react-native'
import QrReader from 'react-web-qr-reader';

import * as S from './style'
import { Button } from '../Button';

interface ScanProps {
  setCamOpened:  React.Dispatch<React.SetStateAction<boolean>>
  onError: (error: any) => void
  getResult: (scan: any) => void
}

const ScanQRCode: React.FC<ScanProps> = ({ setCamOpened, onError, getResult }) => {

  const closeCam = () => {
    setCamOpened(() => false)
  }

  return (
    <S.Container 	showsHorizontalScrollIndicator={false}>
      <S.Header>
        <S.BackText onPress={closeCam}>
          Voltar
        </S.BackText>
      </S.Header>
      <S.AlertArea>
        <S.AlertText>
          Aponte para o <S.AlertTextBold>QR Code</S.AlertTextBold> da trava de segurança
        </S.AlertText>
        <S.WarningArea>
          <S.WarningImage source={require('../../assets/info_gray.svg')} />
          <S.TextWarning>Não leve os produtos sem pagar</S.TextWarning>
        </S.WarningArea>
      </S.AlertArea>
      <S.ScannerArea>
        <S.ScannerAreaCenter>
          <QrReader onScan={getResult} delay={300} onError={onError} showViewFinder={false} />
        </S.ScannerAreaCenter>

       <S.ScannerCorner corner="topLeft" />
       <S.ScannerCorner corner="topRight" />
       <S.ScannerCorner corner="bottomLeft" />
       <S.ScannerCorner corner="bottomRight" />
      </S.ScannerArea>
      <S.FooterScan>
        <Button onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=+558521806454&text=Ol%C3%A1%20preciso%20de%20ajuda.')}>
          <S.FooterText>
            Não achei o QR Code: falar com suporte
          </S.FooterText>
        </Button>
      </S.FooterScan>
    </S.Container>
  );
}

export default ScanQRCode