import React, { ReactFragment } from "react";
import { Animated, TouchableOpacity } from "react-native";
import { theme } from "../../../global/theme";
import { ContainerPress, Container, Panel, Content } from "./index-styles";

interface ModalProps {
	show: boolean;
	animation: Animated.Value;
	close: () => Promise<void>;
	children?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
	show,
	animation,
	close,
	children,
}) => {
	const onClose = async () => {
		await close();
	};

	return (
		<Container
			show={show}
			style={{
				backgroundColor: animation.interpolate({
					inputRange: [0, 100],
					outputRange: [
						`${theme.colors.black}00`,
						`${theme.colors.black}90`,
					],
				}),
			}}
		>
			<ContainerPress
				onPress={onClose}
				testID="btn_close_modal"
			>
				<Content>
					<Panel
						style={{
							marginBottom: animation.interpolate({
								inputRange: [0, 100],
								outputRange: ["-200%", "0%"],
							}),
						}}
					>
						{children}
					</Panel>
				</Content>
			</ContainerPress>
		</Container>
	);
};
