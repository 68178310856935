import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { Header } from "../../components/Header";
import {
	Container,
	ContainerRow,
	Content,
	IconContainer,
	ImageContainer,
	PixValueContainer,
	TextButton,
	Value,
	ContainerImageStore,
	ContainerStores,
} from "./styles";
import { Linking } from "react-native";

async function redirectToSupport() {
	try {
		Linking.openURL(
			"https://api.whatsapp.com/send?phone=+558521806454&text=Olá preciso de ajuda."
		);
	} catch (error) {
		console.log(error);
	}
}

const download = require("../../../assets/download.svg");
const headset = require("../../../assets/headset.svg");
const playstore = require("../../../assets/playstore.svg");
const appstore = require("../../../assets/appstore.svg");

const Support: React.FC = () => {
	const navigation = useNavigation();

	return (
		<>
			<Header />
			<Container>
				<Content>
					<PixValueContainer>
						<Value testID="pix_value">Ajuda</Value>
					</PixValueContainer>
					<ContainerRow onPress={redirectToSupport}>
						<IconContainer>
							<Feather
								name="alert-circle"
								size={32}
								color="#30374A"
							/>
						</IconContainer>
						<TextButton>Problema com o produto</TextButton>
					</ContainerRow>
					<ContainerRow onPress={redirectToSupport}>
						<ImageContainer
							resizeMode="contain"
							source={headset}
						/>
						<TextButton>Falar com o suporte</TextButton>
					</ContainerRow>
					<ContainerRow onPress={redirectToSupport}>
						<ImageContainer
							resizeMode="contain"
							source={download}
						/>
						<TextButton>Baixar o app</TextButton>
					</ContainerRow>
					<ContainerStores>
						<ContainerImageStore
							resizeMode="cover"
							source={appstore}
						/>
						<ContainerImageStore
							resizeMode="cover"
							source={playstore}
						/>
					</ContainerStores>
				</Content>
			</Container>
		</>
	);
};

export default Support;
