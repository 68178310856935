import styled from "styled-components/native";
import { theme } from "../../global/theme";
import styledJS from "styled-components";

export const Modal = styled.Modal.attrs({
	transparent: true,
})``;

interface Props {
	selected: boolean;
}

export const Container = styled.View`
	flex: 1;
	background-color: #00000060;
	align-items: center;
	justify-content: center;
`;

export const TextChangeUnit = styled.Text`
	font-size: 10px;
	color: #fff;
	text-align: center;
	margin-bottom: 10px;
`;

export const PolygonIcon = styled.Image``;

export const ContainerContentModal = styled.View`
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 80%;
	background-color: white;
	padding: 20px;
	border-radius: 20px;
`;

export const TextModalTitle = styled.Text`
	font-size: 22px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.tertiary};
	margin-left: 15px;
`;

export const TextModalTitleError = styled.Text`
	font-size: 22px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.tertiary};
	text-align: center;
`;

export const TextModalContent = styled.Text`
	font-size: 25px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.black};
	text-align: center;
`;

export const TextModalContentError = styled.Text`
	font-size: 16px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.black};
	text-align: center;
`;

export const TextModalContentBold = styled.Text`
	font-size: 25px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.primary};
	text-align: center;
`;

export const ConfirmButton = styled.TouchableOpacity`
	display: flex;
	width: 267px;
	height: 55px;
	border-radius: 10px;
	background-color: ${theme.colors.primary};
	justify-content: center;
`;

export const TextConfirmButton = styled.Text`
	font-size: 16px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.white};
	text-align: center;
`;

export const TextAnother = styled.Text`
	font-size: 11px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.black};
`;

export const ChangeButton = styled.TouchableOpacity`
	display: flex;
	justify-content: center;
	width: 170px;
	height: 35px;
	box-sizing: border-box;
	background: ${theme.colors.white};
	border: 1px solid ${theme.colors.primary};
	border-radius: 10px;
`;

export const TextChange = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.primary};
	text-align: center;
`;

export const ContainerModalTitle = styled.View`
	flex-direction: row;
	margin-bottom: 20px;
`;

export const ContainerModalContent = styled.View`
	justify-content: center;
	margin-bottom: 20px;
	width: 100%;
	margin-top: 20px;
`;

export const ContainerModalContentConfirmBtn = styled.View`
	margin-top: 20px;
`;

export const ContainerModalContentTextBtn = styled.View`
	margin-top: 20px;
`;

export const ContainerModalContentChangeBtn = styled.View`
	margin-top: 20px;
`;

export const ContainerModalContentErrorText = styled.View`
	justify-content: center;
	margin-bottom: 20px;
`;

export const ContainerModalContentErrorBtn = styled.View`
	justify-content: flex-end;
`;
