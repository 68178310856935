import { Dimensions } from "react-native";
import styled from "styled-components/native";
import { theme } from "../../global/theme";
import { Button } from '../../components/Button';

const { width } = Dimensions.get('window');

const isLargerThan417 = width >= 370 && width <= 412;
const isLargerThan370 = width <= 370 && width >= 325;
const isLessThan320 = width <= 320;


export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
	position: relative;
`;

export const Content = styled.ScrollView`
  flex: 1;
  margin: 0 20px;
`;

export const BackContainer = styled.TouchableOpacity`
	margin-top: 20px;
	margin-left: 20px;
`;

export const BackText = styled.Text`
	padding-bottom: 16px;
	font-size: 14px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.primary};
`;

export const AddToCartContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-horizontal: 20px;
  border-top-width: 0.8px;
  border-top-color: #ededed;
  background-color: #fff;
`;

export const ButtonContainerAdd = styled.View`
`;

export const OverlayOffer = styled.View`
  padding-top: 10px;
`;

export const ProductBox = styled.View`
  border-width: 0.8px;
  border-color: #ededed;
  border-radius: 10px;
  margin-bottom: 20px;
  padding-bottom: 10px;
`;

export const OfferLabel = styled.Image`
  position: absolute;
  left: 0px;
  top: 1px;
  z-index: 3;
  width: 50px;
`;

export const ImageWrapper = styled.View`
  height: 215px;
	justify-content: center;
`;

export const ProductImage = styled.Image`
  height: 215px;
  margin: 2px;
`;

export const NoImage = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const ProductName = styled.Text`
  font-size: 18px;
  font-family:  ${theme.fonts.medium};
  color:  ${theme.colors.tertiary};
  border-top-width: 0.8px;
  border-top-color: #ededed;
  padding: 16px;
`;

export const PriceWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-top: auto;
`;

export const RiskedPrice = styled.Text`
  font-size: 16px;
  font-family: ${theme.fonts.regular};
  color: ${theme.colors.gray};
  text-decoration: line-through;
`;

export const FinalProductPrice = styled.Text<{ isOffer: boolean }>`
  font-size: 16px;
  font-family: ${theme.fonts.bold};
  color: ${({ isOffer }) =>
    isOffer ? theme.colors.primary : theme.colors.tertiary};
  margin-left: auto;
`;

export const DescriptionBox = styled.View`
  padding: 16px;
  border-width: 0.8px;
  border-color: #ededed;
`;

export const DescriptionText = styled.Text`
  font-size: 16px;
  color: #000000;
  font-family: ${theme.fonts.regular};
`;
export const ContainerMessage = styled.View`
  background-color: red;
  padding: 8px;
  width: 100%;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;
export const Message = styled.Text`
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;
export const Space = styled.View`
  height: 100px;
`;

export const ButtonsContainer = styled.View`
  padding-bottom: 15px;
  padding-top: 15px;
  padding-horizontal: 20px;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${theme.colors.white};
  align-items: center;
  border-top-width: 0.8px;
  border-top-color: #ededed;
`;
export const ContinueBuyingBorder = styled(Button)`
  width: 49%;
`;
export const ContinueBuying = styled(Button)`
  background-color: ${theme.colors.primary};
  width: 49%;
`;

export const TextAddToCart = styled.Text`
  color: ${theme.colors.gray};
  font-family: ${theme.fonts.medium};
  font-size: ${ isLargerThan417 ? '14px' : isLargerThan370 ? '12px' : isLessThan320 ? '10px' : '16px' };
`;

export const AreaAddProduct = styled.View`
  width: 49%;
  flex-direction: row;
  justify-content: center;
`

