export function formatDate(
  unformattedDate = new Date(),
  separator: '/' | '-' = '/',
): string {
  const date = unformattedDate,
    day = date.getDate().toString(),
    dayFormatted = day.length === 1 ? '0' + day : day,
    month = (date.getMonth() + 1).toString(),
    monthFormatted = month.length === 1 ? '0' + month : month,
    yearFormated = date.getFullYear();

  return dayFormatted + separator + monthFormatted + separator + yearFormated;
}

export function invertDateString(date: string): string {
  const separator = date.includes('/') ? '/' : '-';

  let day = date.split(separator)[0];
  day = day.length === 1 ? '0' + day : day;

  const month = date.split(separator)[1];
  const year = date.split(separator)[2];

  return year + separator + month + separator + day;
}

export function formatDateString(date: string): string {
  const separator = date.includes('/') ? '/' : '-';

  const year = date.split(separator)[0];
  const month = date.split(separator)[1];
  let day = date.split(separator)[2].substr(0, 2);

  return year + '/' + month + '/' + day;
}

export function isValidDate(date: string): boolean {
  const separator = date.includes('/') ? '/' : '-';

  const dateToCheck = Number(date.split(separator)[0]);
  const monthToCheck = Number(date.split(separator)[1]) - 1;
  const yearToCheck = Number(date.split(separator)[2]);

  var dateObj = new Date(yearToCheck, monthToCheck, dateToCheck);

  return dateObj.getFullYear() == yearToCheck &&
    dateObj.getMonth() == monthToCheck &&
    dateObj.getDate() == dateToCheck
    ? true
    : false;
}
