import { Socket, io } from 'socket.io-client';

import { endpoints } from "../env";

const socket: Socket = io(
  endpoints.api,
  { autoConnect: false, transports: ['websocket'] },
);

socket.on('connect', () => {
  console.log('Socket conectado com sucesso!', socket.id);
});

socket.on('disconnect', () => {
  console.log('Socket desconectado!', socket.id);
});

export const connectSocket = (): void => {
  socket.connect();
};

export const disconnectSocket = (): void => {
  socket.disconnect();
};

export const subscribeToEvent = (eventName: string, callback: (...args: any[]) => void): void => {
  socket.on(eventName, callback);
};

export const unsubscribeFromEvent = (eventName: string): void => {
  socket.off(eventName);
};

export const emitEvent = (eventName: string, data: any, callback?: (response: any) => void): void => {
  socket.emit(eventName, data, callback);
};
