import { useQuery } from '@tanstack/react-query';

import api from '../../services/apiSDK';
import { queryKeys } from '../queryClient';

export default function useLockers(storeId: number) {
  const queryFn = async () => {
    const { data } = await api.store.lock.get({
      page: 1,
      perPage: 10,
      storeId,
    });
    return data;
  };

  return useQuery({
    enabled: !!storeId,
    queryFn,
    queryKey: queryKeys.LOCKS(storeId),
    refetchInterval: 180 * 1000, // Refresh every minute
  });
}
