import { useContext } from 'react';
import { CustomContext } from '../contexts';

export const useStore = () => {
  const context = useContext(CustomContext);

  if (!context) {
    throw new Error('useStore must be used within a CustomContext');
  }

  return context;
};
