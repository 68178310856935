import React, { useState } from 'react';
import { NotificationBarContext } from '../contexts/notification-bar-context';

export const NotificationBarProvider: React.FC = ({ children }) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [message, setMessage] = useState('');

  const createNotification = (message: string, time = 2000) => {
    timer && clearTimeout(timer);

    setMessage(message);
    setTimer(setTimeout(() => setTimer(undefined), time));
  };

  return (
    <NotificationBarContext.Provider
      value={{ notify: createNotification, show: !!timer, message: message }}
    >
      {children}
    </NotificationBarContext.Provider>
  );
};
