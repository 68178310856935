import { createGlobalStyle } from "styled-components";
import styled from "styled-components/native";

export const StyleDotted = createGlobalStyle`
	.slider {
  margin:0 20px;
  overflow:"hidden";
  padding:2rem 0;
}

.slider img {
  width: 100%;
  border-radius:10px;
}

.react-multi-carousel-list {
padding: 0rem 0 2rem 0;
}


.react-multi-carousel-dot button {
	background-color: #D9D9D9;
	border:none;
	width: 10px;
	height: 10px;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button{
	background: #FF7700 !important;
}
`;

export const Button = styled.TouchableOpacity`
  border-radius: 12px;
  justify-content: center;
  background-color: "white";
  align-items: center;
  padding-horizontal: 20px;
`;

type ContainerProps = {
	dataLength: number;
};

export const Image = styled.Image`
  width: 100%;
  height: 168px;
  border-radius: 12px;
  border: 1px solid #f6f6f6;
`;

export const Container = styled.View<ContainerProps>`
  margin-bottom: 22px;
  ${(props) => {
		if (props.dataLength == 0) {
			return "margin-bottom: 10px";
		}
	}}
`;
