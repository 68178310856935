import React from 'react';
import { FAB } from 'react-native-paper';
import { View } from 'react-native';
import { Barcode } from './styles';

interface Props {
  onPress: () => void;
}

const IconBarcode = () => {
  return (
    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -2 }}>
      <Barcode />
    </View>
  );
}

const BarcodeButton = (params: Props) => {
  return (
      <FAB
        style={{ backgroundColor: '#FF7700', justifyContent: 'center', alignItems: 'center' }}
        icon={IconBarcode}
        onPress={params.onPress}
      />
  );
}

export default BarcodeButton;