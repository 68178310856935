import { Advert } from '@inhouse-market/sdk';
import { useQuery } from '@tanstack/react-query';

import api from '../../services/apiSDK';
import { queryKeys } from '../queryClient';

export default function useAdverts(params: Advert.RequestAllAdverts) {
  const queryFn = async () => {
    const data = await api.product.advert.get(params);
    return data;
  };

  return useQuery({
    queryFn,
    queryKey: queryKeys.ADVERTS(params.showIn, params.storeId),
  });
}
