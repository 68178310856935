import React from "react";
import { useModal } from "../../../hooks";
import {
	ButtonContainer,
	Container,
	Description,
	OptionButton,
	OptionButtonText,
	Title,
	Content,
	CopyPixImage,
} from "./styles";

const CopyPixSVG = require("../../../../assets/copy_pix.svg");

export const CopyPix: React.FC = () => {
	const modal = useModal();

	const onCancel = async () => {
		await modal.close();
	};

	return (
		<Container>
			<Content>
				<CopyPixImage
					resizeMode="contain"
					source={CopyPixSVG}
				/>
				<Title>Código pix copiado!</Title>
				<Description>
					Agora é só colar o código pix no aplicativo do seu banco e
					efetuar o pagamento!
				</Description>
			</Content>
			<ButtonContainer>
				<OptionButton
					left={false}
					onPress={onCancel}
					testID="btn_confirm_remotion"
				>
					<OptionButtonText>OK</OptionButtonText>
				</OptionButton>
			</ButtonContainer>
		</Container>
	);
};
