import styled, { css } from "styled-components/native";
import { Button } from "../../../components/Button";
import { theme } from "../../../global/theme";

type Props = {
	left: boolean;
};

export const Container = styled.View`
	align-items: center;
`;

export const Title = styled.Text`
	font-size: 18px;
	text-align: center;
	font-family: ${theme.fonts.bold};
	margin-top: 10px;
`;

export const Description = styled.Text`
	font-size: 12px;
	text-align: center;
	margin-top: 6px;
	margin-bottom: 30px;
	width: 70%;
	font-family: ${theme.fonts.regular};
`;

export const Content = styled.View`
	margin-horizontal: 10px;
	align-items: center;
	margin-top: 20px;
`;

export const ButtonContainer = styled.View`
	flex-direction: row;
	width: 100%;
	border: 1px solid #d7d7d7;
	border-left-width: 0px;
	border-right-width: 0px;
	border-bottom-width: 0px;
	align-items: center;
`;

export const OptionButton = styled.TouchableOpacity<Props>`
	padding: 20px;
	flex: 1;
	align-items: center;
	justify-content: center;
	${(props) => {
		if (props.left) {
			return css`
				border: 1px solid #d7d7d7;
				border-top-width: 0px;
				border-right-width: 0px;
				border-bottom-width: 0px;
			`;
		}
		return css`
			border-right: 1px solid #d7d7d7;
			border-top-width: 0px;
			border-left-width: 0px;
			border-bottom-width: 0px;
		`;
	}}
`;

export const OptionButtonText = styled.Text`
	font-family: ${theme.fonts.bold};
	font-size: 18px;
	color: #2081ff;
`;

export const CopyPixImage = styled.Image`
	width: 60px;
	height: 60px;
`;
