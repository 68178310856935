import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const ContainerTitle = styled.View`
	width: 100%;
	justify-content: center;
	background-color: ${theme.colors.white};
`;

export const ContentTitle = styled.View`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const BackText = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.primary};
	text-decoration: underline;
	text-decoration-color: ${theme.colors.primary};
`;

export const ContentItemBackText = styled.TouchableOpacity`
	display: flex;
	align-self: flex-start;
`;

export const ContentItemTitleText = styled.View`
	display: flex;
	width: 100%;
	align-items: center;
`;

export const Content = styled.View`
	flex: 1;
	padding: 20px;
`;

export const Title = styled.Text`
	font-size: 18px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.tertiary};
	text-align: center;
`;

export const SearchInput = styled.TextInput``;

export const RecentSearchesSection = styled.View`
	width: 100%;
`;

export const RecentSearchesTitle = styled.Text`
	font-size: 18px;
	font-family: ${theme.fonts.regular};
	margin-bottom: 12px;
	margin-top: 24px;
`;

export const LastSearches = styled.FlatList`
	height: 70%;
`;

export const RecentSearch = styled.Pressable`
	flex-direction: row;
	padding: 13px 0;
	border-bottom-width: 1px;
	border-bottom-color: #f3f3f3;
`;

export const SearchText = styled.Text`
	color: ${theme.colors.gray};
`;

export const Results = styled.View`
	width: 100%;
	height: 80%;
	margin-top: 15px;
`;

export const Result = styled.TouchableOpacity`
	padding: 7px 2px;
`;

export const ResultText = styled.Text`
	color: ${theme.colors.tertiary};
	font-size: 14px;
	font-family: ${theme.fonts.medium};
`;

export const EmptyCartContainer = styled.View`
	width: 100%;
	height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const EmptyCartIcon = styled.Image`
	display: flex;
	width: 182px;
	height: 182px;
`;

export const NoItemsContainer = styled.View`
	flex: 1;
	align-items: center;
	justify-content: center;
	background-color: ${theme.colors.white};
`;

export const NoItemsText = styled.Text`
	width: 317px;
	font-size: 18px;
	text-align: center;
	padding-top: 20px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.black};
`;

export const BtnContainer = styled.View`
	width: 100%;
	height: 20%;
	margin-top: 30px;
	justify-content: space-evenly;
	align-items: center;
`;

export const ConfirmButton = styled.TouchableOpacity`
	display: flex;
	width: 90%;
	max-width: 335px;
	height: 55px;
	border-radius: 10px;
	background-color: ${theme.colors.primary};
	justify-content: center;
`;

export const TextConfirmButton = styled.Text`
	font-size: 16px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.white};
	text-align: center;
`;

export const ChangeButton = styled.TouchableOpacity`
	display: flex;
	width: 215px;
	height: 35px;
	border-radius: 10px;
	border-width: 1px;
	border-color: ${theme.colors.primary};
	background-color: ${theme.colors.white};
	justify-content: center;
`;

export const TextChange = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.medium};
	color: ${theme.colors.primary};
	text-align: center;
`;

export const ContainerContentEmptyCart = styled.View`
	display: flex;
	height: 80%;
	align-items: center;
	justify-content: center;
`;

export const ContentEmptyCart = styled.View`
	display: flex;
	height: 80%;
	align-items: center;
	justify-content: space-evenly;
`;

export const ContainerProduct = styled.View`
	width: 100%;
`;

export const TextOr = styled.Text`
	text-align: center;
	font-size: 15px;
	margin-top: 10px;
	margin-bottom: 10px;
`;
