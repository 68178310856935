import { createContext, ReactFragment } from "react";

interface ModalContextProps {
  show(children: ReactFragment): Promise<void>;
  close(): Promise<void>;
}

export const ModalContext = createContext<ModalContextProps>(
  {} as ModalContextProps
);
