import styled, { css } from "styled-components/native";
import { theme } from '../../global/theme';

interface ButtonContainerProps {
  disabling?: boolean;
  type?: "primary" | "secondary" | "secondary_ghost" | "primary_ghost";
}

export const ButtonContainer = styled.TouchableOpacity<ButtonContainerProps>`
  width: 100%;
  /* max-width: 335px; */
  height: 55px;

  ${({ type }) =>
    type === "secondary_ghost" &&
    `
  border: 1.5px solid ${theme.colors.secondary};
  `}
    ${({ type }) =>
    type === 'primary_ghost' &&
    `
    border: 1.5px solid ${theme.colors.primary};
  `}
  background: ${({ theme, type }) =>
    type === "primary"
      ? "#FF7700"
      : type === "secondary"
        ? '#2081FF'
        : '#FFFFFF'};
  ${({ disabling }) =>
    disabling &&
    css`
      background-color: ${theme.colors.disabled};
    `}
  border-radius: 10px;
  
  justify-content: center;
  align-items: center;

  align-self: center;

  /* margin-bottom: 16px; */
`;

export const Title = styled.Text<ButtonContainerProps>`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: ${({ type }) =>
    type === "primary" ? "Montserrat_600SemiBold" : "Montserrat_500Medium"};
  font-size: ${({ type }) => (type === "primary" ? "18px" : "14px")};
    color: ${({ type }) => {
    if (type === 'secondary_ghost') {
      return theme.colors.secondary;
    } else if (type === 'primary_ghost') {
      return theme.colors.primary;
    } else {
      return theme.colors.white;
    }
  }};
  ${({ disabling }) =>
    disabling &&
    css`
      color: ${theme.colors.disabledButtonText};
    `}
`;
