import { createContext } from 'react';

interface CustomProps {
  storeId: string;
  currentLatitude: string;
  currentLongitude: string;
  setStore: (value: string) => void
}

export const CustomContext = createContext<
  CustomProps | undefined
>(undefined);
